<template>
    <Transition>

        <div class="global-popup section-popup auth-popup pop-fade popup-location-desc">

            <div class="popup-bg" v-on:click="$emit('close-popup'), body.removeAttribute('style'), closePop()"></div>
            <div class="popup-container">
                <div class="popup-close popout" v-if="winW >= 768" data-popup="popup-content"
                    v-on:click="$emit('close-popup'), body.removeAttribute('style'), closePop()"><img
                        src="@/assets/img/skin/icon-close.svg" alt=""></div>

                <div class="box-popup">

                    <div class="popup-body scroll" v-for="(cont, index) in popupContArray " :key="index">
                        <div class="popout position-relative btn-icon py-md-4 py-2" v-if="winW < 768"
                            data-popup="popup-content"
                            v-on:click="$emit('close-popup'), body.removeAttribute('style'), closePop()"><span
                                class="justify-content-end"><img src="@/assets/img/skin/icon-back.svg" class="svg"
                                    alt=""><span>กลับ</span></span></div>

                        <div class="row">
                            <div class="col-left col-12 col-md-5">
                                <div class="gallery">
                                    <div class="swiper-container gallery-top">
                                        <div class="swiper-wrapper">
                                            <template v-for="(image, index) in cont.images " :key="index">
                                                <div v-if="image != '' &&  image != null" class="swiper-slide">
                                                    <img :style="`height:${imgH}px`"  :src="require(`@/assets/img/77-attractions/${replaceExtension(image)}`)" alt="">
                                                </div>
                                            </template>

                                        </div>
                                        <div class="swiper-button-next"> <img class="svg"
                                                src="@/assets/img/skin/icon-arrow-r.svg" alt=""></div>
                                        <div class="swiper-button-prev"><img class="svg"
                                                src="@/assets/img/skin/icon-arrow-l.svg" alt=""></div>
                                    </div>
                                    <div class="swiper-container  gallery-thumbs" v-if="imageOfsize">
                                        <div class="swiper-wrapper">
                                            <template v-for="(image, index) in cont.images " :key="index">
                                                <div v-if="image != '' &&  image != null" class="swiper-slide" :style="`height:${imgB}px;`">
                                                    <img :style="`height:${imgB}px;width:${imgB}px;`"  :src="require(`@/assets/img/77-attractions/${replaceExtension(image)}`)" alt="">
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-right col-12 col-md-7 popup-right-content position-relative">
                                <div class="box-location-group-head">
                                    <div class="box-location-head">
                                        <h3 class="primary txt-w-xl" v-html="cont.location_name"></h3>
                                    </div>
                                </div>
                                <p v-if="cont.location_link == ''" class="gray-dark pb-2 btn-icon txt-location txt-w-og d-block">
                                    <span class="justify-content-start"><img src="@/assets/img/skin/icon-pin.svg"
                                            class="svg" alt=""><span>{{ cont.location }} จ.{{ cont.province_th }} {{ cont.province_th }} {{
                                                cont.region_th }}</span></span>
                                </p>
                                <a v-else :href="cont.location_link" target="_blank" 
                                    class="gray-dark pb-2 btn-icon txt-location txt-w-og d-block"><span
                                        class="justify-content-start"><img src="@/assets/img/skin/icon-pin.svg" class="svg"
                                            alt=""><span>{{ cont.location }} จ.{{ cont.province_th }} {{ cont.region_th
                                            }}</span></span></a>
                                <div class="pop-travel-content gray-dark " :style="`${styleH}:${contH}px`">
                                    <p v-html="cont.detail"></p>
                                </div>

                                <div class="location-vote-popup d-block">
                                    <div v-if="cont.tel != ''" class="gray-dark mb-3 fw btn-icon txt-location txt-w-og box-contact-location">
                                        <span class="justify-content-start"><img src="@/assets/img/skin/icon-phone.svg"
                                                class="svg" alt=""><span v-html="cont.tel"></span></span></div>
                                    <div class="w-100 d-flex justify-content-between">
                                        <a href="javascript:void(0)" v-if="winW >= 768" 
                                            @click="handleVote(cont.id, cont.image_cover, cont.location, cont.province_th, cont.region_th) ,handleClick('Vote-popup',cont.location_name)"
                                            class="card-like card-action-default" :class="isVote(cont.region_en, cont.id)">
                                            <span><img src="@/assets/img/skin/icon-heart-vote.svg" class="svg" alt=""><span
                                                    class="vote-num">{{ cont.vote >= 1000 ? (Math.round(cont.vote)).toLocaleString() : Math.round(cont.vote) }}</span><span
                                                    class="vote-txt">VOTE</span></span>
                                        </a>
                                        <div class="group-share display-flex align-items-center" v-if="winW >= 768">
                                            <div class="box-share">
                                                <p class="me-2">แชร์</p>
                                                <a href="javascript:void(0);" @click="() => { openFacebookShare(); handleClick('share-popup', cont.location_name); }">
                                                    <img class="svg" src="@/assets/img/skin/icon-f-blue.svg" alt="">
                                                 </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="box-vote-sticky" v-if="winW < 768">
                            <a href="javascript:void(0)" 
                                @click="handleVote(cont.id, cont.image_cover, cont.location_name, cont.province_th, cont.region_th),handleClick('Vote-popup',cont.location_name)"
                                class="card-like card-action-default" :class="isVote(cont.region_en, cont.id)">
                                <span><img src="@/assets/img/skin/icon-heart-vote.svg" class="svg" alt=""><span
                                        class="vote-num">{{ cont.vote >= 1000 ? (Math.round(cont.vote)).toLocaleString() : Math.round(cont.vote) }}</span><span class="vote-txt">VOTE</span></span>
                            </a>
                            <div class="group-share display-flex align-items-center" >
                                <div class="box-share">
                                    <p class="me-2">แชร์</p>
                                    <a href="javascript:void(0);" @click="() => { openFacebookShare(); handleClick('share-popup', cont.location_name); }">
                                            <img class="svg" src="@/assets/img/skin/icon-f-blue.svg" alt="">
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
/* eslint-disable */
import { setFunction } from '@/global'
import Swiper, { Autoplay, Navigation, Pagination, Controller, EffectFade, } from 'swiper';
Swiper.use([Autoplay, Navigation, Pagination, Controller, EffectFade]);
import { clickTracking } from '@/main';
import axios from 'axios';
import 'swiper/css';
export default {
    props: ['popupContArray'],
    data() {
        return {
            cardContent: '',
            cardImageArray: [],
            galleryTop: null,
            galleryThumbs: null,
            contH: 0,
            body: document.querySelector('body'),
            pageResponeRs: [],
            pageTitle: "",
            metaTitle: "",
            metaKeywords: "",
            metaDescription: "",
            shareImage: "",
            userAssets: sessionStorage.getItem('USER_INFO'),
            profileData: '',
            historyData: null,
            winW: window.innerWidth,
            current_week: '',
            windowHeight: 0,
            styleH: 'min-height',
            imageOfsize: false,
            dataArray:[],
            imgH: 0,
            imgB: 0
        }
    },
    mounted() {


        //// console.log(this.popupContArray);
        this.getWindowHeight();
        window.addEventListener('resize', this.handleResize );
        setTimeout(() => {
            setFunction();
        }, 50);
        if (this.userAssets != null) {
            let decodedDataString = atob(this.userAssets);
            let decodedArray = JSON.parse(decodedDataString);

            this.profileData = decodedArray.profile;
            this.historyData = decodedArray.history;
            this.loginStatus = true;
            this.current_week = 'week_' + decodedArray.history.current_week;
            window.addEventListener("change", (event) => {
                if (sessionStorage.getItem('USER_INFO') !== undefined) {
                    const changedCookie = event.changed[0];
                    if (changedCookie.name === "USER_INFO") {
                        let decodedDataString = atob(changedCookie.value);
                        let decodedArray = JSON.parse(decodedDataString);

                        this.profileData = decodedArray.profile;
                        this.historyData = decodedArray.history;
                        this.current_week = 'week_' + decodedArray.history.current_week;

                    }
                }
            });

        }
        this.winW = window.innerWidth;
        window.addEventListener('resize', () => {
            this.winW = window.innerWidth;
        });

        window.addEventListener('load', () => {
            // this.slideGallery();
            const currentUrl = new URL(window.location.href);

            // var slug = currentUrl.searchParams.get('slug');
            // if (slug != '') {
            //     this.urlInitPopup(slug)
            // }
            // this.hasEmptyImages(this.popupContArray[0].images)
            // // console.log('load',this.popupContArray);
        });



        // const currentUrl = new URL(window.location.href);

        // if (currentUrl.searchParams.get('slug')) {
        //     var slug = currentUrl.searchParams.get('slug');
        //     if (slug != '') {
        //         this.urlInitPopup(slug)
        //     }
        // }
 
        setTimeout(() => {
            const currentUrl = new URL(window.location.href);
            var slug =  currentUrl.searchParams.get('slug')
            if (slug != '') {
                this.urlInitPopup(slug);
            }
        }, 100);
        
        
        this.hasEmptyImages(this.popupContArray[0].images)

        // this.slideGallery();
        this.body.style.overflow = 'hidden'; 
        

        
        const data = this.popupContArray[0].location_name;
        const location_clean = data.replace(/<span class=['"]t-inline['"]>(.*?)<\/span>/g, '');

        
        document.title = location_clean + ' ' +  this.popupContArray[0].location + ' '  + 'จังหวัด' + this.popupContArray[0].province_th + ' ' + this.popupContArray[0].region_th;
        document
            .querySelector('meta[name="keywords"]')
            .setAttribute("content",location_clean + ' ' + this.popupContArray[0].location + ' ' + 'จังหวัด' + this.popupContArray[0].province_th + ' ' + this.popupContArray[0].region_th);
        document
            .querySelector('meta[name="description"]')
            .setAttribute("content", this.popupContArray[0].detail);

        // Set the Open Graph metadata for Facebook sharing.
        document
            .querySelector('meta[property="og:title"]')
            .setAttribute("content",location_clean + ' ' + this.popupContArray[0].location + ' ' + 'จังหวัด' + this.popupContArray[0].province_th + ' ' + this.popupContArray[0].region_th);
        document
            .querySelector('meta[property="og:type"]')
            .setAttribute("content", "website");
        document
            .querySelector('meta[property="og:url"]')
            .setAttribute("content", window.location.href);
        document
            .querySelector('meta[property="og:image"]')
            .setAttribute("content", this.popupContArray[0].image_cover);
        document
            .querySelector('meta[property="og:description"]')
            .setAttribute("content", this.popupContArray[0].detail);

        

        // this.dataArray = this.popupContArray
        
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        replaceExtension(imageUrl) {
            return imageUrl.replace(/\.jpg$/, '.webp');
        },
        handleClick(name,value) {
            try {
                this.clickTracking(name,value);
            } catch (error) {
                
            }
            // Additional logic for handling the button click
        },
        handleResize(){
            setFunction();
            this.getWindowHeight();
            this.calculateSlideWidth();
        },
        hasEmptyImages(images) {
                if (images[0] == '' && images[1] == '' && images[2] == '' && images[3] == '' && images[4] == '' ) {
                    this.imageOfsize = false;
                }else if(images[0] != '' && images[1] == '' && images[2] == '' && images[3] == '' && images[4] == ''){
                    this.imageOfsize = false;
                    setTimeout(() => {
                        this.slideGallery();    
                    }, 100);
                } else {
                    this.imageOfsize = true;
                    
                    setTimeout(() => {
                        this.slideGallery();    
                    }, 100);
                }
        },
        getWindowHeight() {
            this.windowHeight = window.innerHeight;
        },
        // openFacebookShare() {
        //     const facebookShareUrl = `https://uat.unseennewchapters.com/sharefb.php?slug=${this.popupContArray[0].id}`;
        //     window.open('https://www.facebook.com/sharer/sharer.php?u=' + facebookShareUrl,
        //         'facebook-share-dialog',
        //         'width=800,height=600'
        //     );
        //     return false;
        // },

        openFacebookShare() {
            const facebookShareUrl = `https://uat.unseennewchapters.com/sharefb.php?slug=${this.popupContArray[0].id}`;

              // Open the Facebook sharing dialog in the web version
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(facebookShareUrl)}`,
                'facebook-share-dialog',
                'width=800,height=600'
            );
            
            return false;
        },



        async urlInitPopup(slug) {
            const popUpIsslug = slug;
            if (popUpIsslug != undefined || popUpIsslug != '') {
                try {
                    const response = await axios.get(`https://uat.unseennewchapters.com/nx/API/get_locations/${popUpIsslug}`);
                    if (response.data.rs == true) {
                        this.popupContArray = response.data.data;
                        this.hasEmptyImages(this.popupContArray[0].images)
                    } else {
                        // this.noDataFound();
                    }

                } catch (error) {
                    // // console.log(error);
                    // this.noDataFound();
                }
            } else {
                //  this.noDataFound();
            }

        },

        noDataFound() {
            let url = new URL(window.location.href);
            url.searchParams.delete('slug');
            window.history.replaceState(null, null, url.href);
            window.location.reload();
        },

        isVote(locationEN, locationId) {
            const tabRegions = this.historyData;
            if (tabRegions != null) {
                if (tabRegions[this.current_week].hasOwnProperty(locationEN)) {
                    if (tabRegions[this.current_week][locationEN][0].location_id == locationId) {
                        return 'isvote'
                    } else {
                        return 'disableVote'
                    }
                }
            }
        },
        closePop() {
            let url = new URL(window.location.href);
            url.searchParams.delete('slug');
            window.history.replaceState(null, null, url.href);
        },
        calHGiv() {
            if (document.querySelector('.gallery') != null || document.querySelector('.gallery') != undefined) {
                let wrapH = document.querySelector('.gallery').offsetHeight;
                let txtHeader = document.querySelector('.box-location-group-head').offsetHeight;
                let txtLocation = document.querySelector('.txt-location').offsetHeight;

                let VBtnH = document.querySelector('.location-vote-popup').offsetHeight;
                this.contH = wrapH - (txtHeader + txtLocation + VBtnH + 20);


                let popupBody = document.querySelector('.popup-location-desc .popup-body ').offsetHeight;
                //// console.log('popupBody',popupBody , (this.windowHeight - 60));
                if (popupBody > (this.windowHeight - 60)) {
                    const totalPadding = 90;
                    this.styleH = 'max-height';
                    document.querySelector('.pop-travel-content').classList.add('is-scroll');
                    this.contH = (this.windowHeight - 100) - (totalPadding + txtHeader + txtLocation + VBtnH);
                }
            }
            
        },
        handleVote(is_id, is_image_cover, is_location_name, is_province_th, is_region_th) {
            const id = is_id
            const image_cover = is_image_cover
            const location_name = is_location_name
            const province_th = is_province_th
            const region_th  =is_region_th
            this.$emit('vote-clicked', { id, image_cover, location_name, province_th, region_th });
        },

        calculateSlideWidth(){
            var galleryT = document.querySelector('.gallery-top');
            var galleryS = galleryT ? galleryT.querySelectorAll('.swiper-slide') : [];
            if(galleryS.length > 0){
                var galleryS = galleryT.querySelectorAll('.swiper-slide');
                if (galleryS.length > 0) {
                    //// console.log(galleryS[0].offsetWidth);
                    this.imgH = galleryS[0].offsetWidth;
                }
            }
           

            
        },
        
    slideGallery() {
    //// console.log('init');
        var galleryTop = "";
        var galleryThumbs = ""; 
        if (document.querySelector('.gallery-thumbs') != null) {
            galleryThumbs = new Swiper('.gallery-thumbs', {
                spaceBetween: 10,
                slidesPerView: 3,
                //centeredSlides: true,
                touchRatio: 0.2,
                slideToClickedSlide: true,
                preventClicksPropagation: false,
            });
            // galleryTop.controller.control = galleryThumbs;
            // galleryThumbs.controller.control = galleryTop;

            galleryThumbs.on('slideChange', function() {
                //// console.log('galleryThumbs : ' + galleryThumbs.realIndex);
                // if (galleryTop) {
                //     galleryTop.activeIndex = galleryThumbs.realIndex + galleryTop.loopedSlides;
                // }
            });

        }
        if(document.querySelector('.gallery-thumbs .swiper-slide:nth-of-type(1)')){
            document.querySelector('.gallery-thumbs .swiper-slide:nth-of-type(1)').classList.add("slideActive_custom");
        }
        galleryTop = new Swiper('.gallery-top', {
            spaceBetween: 30,
            slidesPerView: 1,
		    loopedSlides: 1,
            navigation: {
                nextEl: '.gallery-top .swiper-button-next',
                prevEl: '.gallery-top .swiper-button-prev',
            },
            
        });
        galleryTop.on('slideChange', function() {
            
            galleryThumbs.slideTo(galleryTop.realIndex, 500);
            var slides_thumbs = document.querySelectorAll('.gallery-thumbs .swiper-slide');
            slides_thumbs.forEach(slides_thumb => {
                slides_thumb.classList.remove("slideActive_custom");
            });
            document.querySelector('.gallery-thumbs .swiper-slide:nth-of-type('+(galleryTop.activeIndex + 1)+')').classList.add("slideActive_custom");
        });
        var buttons_slide = document.querySelectorAll('.gallery-thumbs .swiper-slide');
        
        buttons_slide.forEach(function(button_slide, index) {
            button_slide.addEventListener('click', function() {
                buttons_slide.forEach(function(btn,index) {
                    btn.classList.remove('slideActive_custom');
                });
                this.classList.add('slideActive_custom');
                galleryTop.slideTo(index, 500);
                galleryThumbs.slideTo(index-1, 500);
            });
        });
        

        // galleryThumbs.slideTo(0, 500);
        // setTimeout(function(){
        //     galleryThumbs.slideTo(1, 500);
        // },50);
        // galleryTop.on('slideChange', function(e) {
            
        //     if (galleryThumbs) {
        //         galleryThumbs.activeIndex = galleryTop.realIndex
        //     }
            
        //     // console.log(e.realIndex);
        // });
        
       
            
//   var galleryTop;
//   var galleryThumbs = document.querySelector('.gallery-thumbs');

//   galleryTop = new Swiper('.gallery-top', {
//     spaceBetween: 30,
//     slidesPerView: 1,
//     loop: true,
//     navigation: {
//       nextEl: '.gallery-top .swiper-button-next',
//       prevEl: '.gallery-top .swiper-button-prev',
//     },
//   });

//   galleryTop.on('slideChange', function() {
//     if (galleryThumbs) {
//       galleryThumbs.activeIndex = galleryTop.realIndex + galleryThumbs.loopedSlides;
//     }
//   });

//   setTimeout(() => {
//     var galleryThumbs = new Swiper('.gallery-thumbs', {
//       spaceBetween: 10,
//       slidesPerView: 3,
//       loop: true,
//       slideToClickedSlide: true,
//       loopedSlides: 5, // Adjust the number of looped slides according to your needs
//     });

//     galleryThumbs.on('slideChange', function() {
//       if (galleryTop) {
//         galleryTop.activeIndex = galleryThumbs.realIndex + galleryTop.loopedSlides;
//       }
//     });

//     galleryThumbs.on('click', function() {
//       var clickedSlideIndex = galleryThumbs.clickedIndex;

//       // Remove 'swiper-slide-active' class from all slides
//       var thumbSlides = galleryThumbs.slides;
//       thumbSlides.forEach(function(slide) {
//         slide.classList.remove('swiper-slide-active');
//       });

//       // Add 'swiper-slide-active' class to the clicked slide
//       thumbSlides[clickedSlideIndex].classList.add('swiper-slide-active');

//       // Update galleryTop to the clicked slide index
//       if (galleryTop) {
//         galleryTop.slideTo(galleryThumbs.activeIndex - galleryThumbs.loopedSlides);
//       }
//     });
//   }, 50);

        setTimeout(() => {
            this.calculateSlideWidth();
            var galleryB = document.querySelector('.gallery-thumbs');
            var galleryBS = galleryB ? galleryB.querySelectorAll('.swiper-slide') : [];
            if (galleryBS.length > 0) {
                this.imgB = galleryBS[0].offsetWidth;
            }
        }, 100);
        setTimeout(() => {
            document.querySelector('.pop-fade').classList.add('active');
        }, 150);

        if (this.winW >= 768) {
            setTimeout(() => {
                this.calHGiv();
            }, 120);
        } else {
            this.contH = 'unset';
        }

    },
}


}
</script>


<style>
.gallery-top .swiper-button-next:after,
.gallery-top .swiper-rtl .swiper-button-prev:after {
    content: none !important;
}

.gallery-top .swiper-button-prev:after,
.gallery-top .swiper-rtl .swiper-button-next:after {
    content: none !important;
}

.pop-fade {
    opacity: 0;
    transition: all ease-in-out .5s;
}

.pop-fade.active {
    opacity: 1;
}

.auth-popup .popup-container {
    border-radius: 20px;
    width: 1060px;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.gallery .gallery-top {
    padding: 5px;
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
}
.box-lcation-head{width: calc(100% - 20px);}
.popup-location-desc .gallery .gallery-top .swiper-slide {
    overflow: hidden;
    border-radius: 10px;
    height: 380px;
}
.popup-location-desc .col-left {
    margin-right: 0;
}
.gallery-thumbs .swiper-wrapper{transform: translate3d(0px, 0, 0) ; }

.is-scroll {
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 20px;
}

.popup-body {
    padding: 45px;
}

.swiper-container {
    overflow: hidden;
    position: relative;
    height: auto;
}

.gallery {
    position: relative;
    height: auto
}

.gallery .swiper-container {
    height: auto;
}

.auth-popup .swiper-slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-popup .swiper-slide img {
    width: 100%;
    height: auto;
}

/* .gallery-thumbs {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 80px;
	z-index: 10;
} */

.btn-icon.txt-location > span svg {
    height: 20px;
    width: 20px;
}

.gallery-thumbs .swiper-slide {
    /* width: 100px ;*/
    /* min-height: 100px;  */
    cursor: pointer;
    border-radius: 15px;
    overflow: hidden;
}

.gallery-top .swiper-slide img {
    object-fit: contain;height: 400px;
}
/* .gallery-thumbs .swiper-wrapper{transform: translate3d(0px, 0, 0) !important;} */

.gallery-top .swiper-button-next svg path,.gallery-top .swiper-button-prev svg path{stroke: #EC268F;}

.gallery-thumbs .slideActive_custom{
    border: 2px solid #EC268F;

}

.gallery-thumbs .swiper-slide img {
    float: left;
    width: 100%;
    height: 100%;
}

.auth-popup .location-vote-popup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
    left: 0;
    width: 100%;
    /* width: calc(100% - ((var(--bs-gutter-x) * .5)*2)); */
    border-top: 1px solid #E4E4E4;
    padding-top: 15px;
    margin-top: 15px;
    /* padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5); */
    /* margin-left: calc(var(--bs-gutter-x) * .5); */
}

.auth-popup .isvote {
    pointer-events: none;
}

.box-contact-location a{text-decoration: underline;color: var(--color-gray-dark); font-weight: 400;}
.auth-popup .disableVote svg {
    fill: #B1B1B1;
    pointer-events: none;
}

/* .disableVote svg path{fill: #B1B1B1 !important;} */
.auth-popup .card-like.disableVote svg .path-2 {
    fill: #B1B1B1;
}

.auth-popup .disableVote {
    color: #B1B1B1;
    background-color: #E2E2E2;
    border: 2px solid #E2E2E2;
    pointer-events: none;
}

.auth-popup .isvote svg {
    fill: #fff;
}

.auth-popup .isvote svg path {
    fill: #fff !important;
}

.auth-popup .isvote {
    color: #fff;
    background-color: var(--color-primary);
}

.auth-popup .card-like.isvote svg .path-1 {
    fill: #fff;
}

.popup-location-desc {
    z-index: 1000 !important;
}

.popup-location-desc .group-share {
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-location-desc .box-share {
    display: flex;
    align-items: center;
}

.box-share a {
    cursor: pointer;display:flex;align-items:center;
}

.box-share a svg {
    fill: #4267B2;
    transition: all ease-in .2s;
}

.box-share a:hover svg {
    opacity:0.5
}
.box-location-head{width: calc(100% - 20px);}




@media (max-height: 650.98px) {
    .auth-popup .popup-container{width: 900px;}
}

@media (max-width: 1199.98px) {
    .popup-location-desc .popup-container{width: 900;}
    .popup-location-desc .gallery .gallery-top .swiper-slide{height: 331px;}
    .gallery-top .swiper-slide img{height: 280px;}
    .popup-location-desc .gallery .gallery-top .swiper-slide { min-height:34vw;}
    .box-share a svg {
        fill: #4267B2;
    }
}

@media (max-width: 991.98px) {
    .auth-popup .popup-container {
        width: 90vw;
    }
    .auth-popup .popup-container{width: 706px;}
    .popup-location-desc .gallery .gallery-top .swiper-slide{height: 233px;}
    .popup-location-desc .popup-close {
        top: 10px;
        right: 10px;
    }

    .box-location-head {
        font-size: 0.9em;
    }

    .auth-popup .location-vote-popup {
        padding-top: 15px;
    }

    .txt-location {
        font-size: 0.9em;
    }

    .pop-travel-content {
        font-size: 0.9em;
    }

    .gallery .gallery-top {
        width: 100%;
    }
}

@media (max-width: 767.98px) {
    .auth-popup .popup-container {
        width: 100vw;
    }
    .box-location-head{  width: 100%;}
    .box-location-group-head {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    /* .gallery-top .swiper-slide img{height: 350px;} */

    .gallery .gallery-top {
        width: 100%;
    }

    .popup-location-desc .popup-body.scroll {
        max-height: calc(100vh - 75px);
        overflow: scroll;
        padding: 10px 45px 40px;
    }

    .box-vote-sticky {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background-color: #fff;
        border-top: 1px solid #eee;
    }

    .popup-location-desc.auth-popup .popup-container {
        border-radius: 0;
        height: 100%;
        top: 0;
        transform: translate(-50%, 0);
    }

    .pop-travel-content {
        overflow: visible;
        padding-right: 0;
    }

    .popup-location-desc .popup-bg {
        display: none;
    }

    .popup-location-desc .col-left {
        margin-right: 0;
        padding-right: 0;
    }

    .popup-location-desc.auth-popup .location-vote-popup {
        position: relative;
        padding: 20px 0 0;
        margin: 20px 0 0 0;
        width: 100%;
        justify-content: center;
    }

    .box-location-group-head {
        margin-top: 20px;
    }
    .popup-location-desc .gallery .gallery-top .swiper-slide { min-height:auto;height: auto;} 
    

}

@media (max-width: 575.98px) {
    .gallery-thumbs .swiper-slide{border-radius:8px}

    /* .gallery-top .swiper-slide img{height: 340px;} */
    .popup-location-desc .popup-body.scroll {
        padding: 0 20px 20px;
        padding-bottom:75px;
    }

    .popup-body {
        padding: 45px 5px;
    }

    .popup-location-desc .col-left {
        padding-right: calc(var(--bs-gutter-x) * .5);
    }
    
}

@media (max-width: 419.98px) {
    /* .gallery-top .swiper-slide img{height: 310px;} */
}</style>

