import axios from 'axios';
import { setFunction  } from '@/global'
const state = {
  zones: [],
  loading: false
};

const getters = {
  allZones: (state) => state.zones,
  loading: (state) => state.loading 
};

const actions = {
  async fetchZones({ commit }) {
    try {
      commit('setLoading', true); // set loading to true before the API call
      const response = await axios.get('https://uat.unseennewchapters.com/nx/API/get_locations_zone');
      commit('setZones', response.data.data);
      commit('setLoading', false); // set loading to false after the API call
       setTimeout(() => {
        setFunction();
       }, 500);
    } catch (error) {
      // // console.log(error);
      commit('setLoading', false); // set loading to false if the API call fails
    }
    // try {
    //   const response = await axios.get('https://uat.unseennewchapters.com/nx/API/get_locations_zone');
    //   commit('setZones', response.data.data);
    // } catch (error) {
    //   // // console.log(error);
    // }
  }
};

// const mutations = {
//   setZones: (state, zones) => (state.zones = zones)
// };
const mutations = {
  setZones: (state, zones) => (state.zones = zones),
  setLoading: (state, loading) => (state.loading = loading) // define a setLoading mutation
};

export default {
  state,
  getters,
  actions,
  mutations
};
