// store/modules/auth.js

import axios from 'axios';

const state = {
  token: localStorage.getItem('token') || '',
  status: '',
};

const getters = {
  isAuthenticated: state => !!state.token,
};

const actions = {
  async register({ commit }, { username, tel }) {
    commit('auth_request');
    try {
      const tokenResponse = await axios.get('https://uat.unseennewchapters.com/nx/API/get_token');
      const token = tokenResponse.data.hash;
      localStorage.setItem('token', token);

      const formData = new FormData();
      formData.append('username', username);
      formData.append('tel', tel);
      formData.append('token', token);

      const response = await axios.post('https://uat.unseennewchapters.com/nx/API/register', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      commit('auth_success', token);
      return response;
    } catch (error) {
      commit('auth_error');
      // console.error(error);
    }
  },
  logout({ commit }) {
    localStorage.removeItem('token');
    commit('logout');
  },
};

const mutations = {
  auth_request(state) {
    state.status = 'loading';
  },
  auth_success(state, token) {
    state.status = 'success';
    state.token = token;
  },
  auth_error(state) {
    state.status = 'error';
  },
  logout(state) {
    state.status = '';
    state.token = '';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
