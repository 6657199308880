<template>
    <div v-if="pdpa_consent != 'active'" class="cookie-bar"  :class="[{ active: box_pdpa === true }]">
        <div class="cookie-body">   
            <div class="container">
                <div class="row">
                    <div class="col col-xl-9 col-md-8 col-12">
                        <div class="cookie-title cookie-title-lang"><span>เว็บไซต์นี้ใช้คุกกี้</span></div>
                    <!-- <div class="cookie-bar-lang cookie-sub-text fw">เราใช้คุกกี้เพื่อเพิ่มประสิทธิภาพ และประสบการณ์ที่ดีในการใช้งานเว็บไซต์ คุณสามารถเลือกที่จะยอมรับคุกกี้ของเราโดยเพียงแค่คลิก “ยอมรับทั้งหมด” และสามารถดูข้อมูล <a target="_blank" href="https://thai.tourismthailand.org/Information/นโยบายความเป็นส่วนตัว">นโยบายความเป็นส่วนตัว</a> ได้ </div> -->
                    <div class="cookie-bar-lang cookie-sub-text fw">เราใช้คุกกี้นี้สำหรับโครงการ Unseen New Chapters เพื่อประโยชน์ในการเก็บรวบรวมฐานข้อมูลสถิติผู้สนใจในกิจกรรมและร่วมลุ้นรับของรางวัลจากการจัดกิจกรรม คุณสามารถเลือกที่จะยอมรับคุกกี้ของเราโดยเพียงแค่คลิก “ยอมรับทั้งหมด” และสามารถดูข้อมูล <a target="_blank" href="https://thai.tourismthailand.org/Information/นโยบายความเป็นส่วนตัว">นโยบายความเป็นส่วนตัว</a> ได้ </div>
                    </div>
                    <div class="col col-xl-3 col-md-4 col-12">
                        <div class="cookie-button">
                            <button class="close d-md-block d-none btn btn-link btn-txt btn-ctr-secondary"  @click="box_pdpa = false , closeCookie()" ><span class="material-symbols-outlined">ปิด</span></button>
                            <button class="close d-md-none btn btn-link btn-txt btn-ctr-secondary" @click="box_pdpa = false,closeCookie()"  >ปิด</button>
                            <button class="btn btn-accept " @click="pdpa_acceptAll();">ยอมรับทั้งหมด</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div>
        <!-- <div class="global-popup section-popup  close-campaign">
            <div class="popup-bg" @click="closePopUpCam()"></div>
            <div class="popup-container">
                <div class="popup-close popout"  @click="closePopUpCam()" data-popup="popup-content"><img src="@/assets/img/skin/icon-close.svg" alt=""></div>
                <div class="box-popup">
                    <div class="popup-body ">
                        <div class="row justify-content-center">
                            <div class="col-12 col-md-10">
                                <div class="pop-cont-overlap">
                                   
                                    <div class="fw text-center  box-content" v-if="isCurrent_week == 1 ||  isCurrent_week == 2 || isCurrent_week == 3">
                                        <h6 class="txt-w-lg primary">ขณะนี้หมดเวลา<span style="display: inline-block;">โหวตของสัปดาห์นี้แล้ว</span></h6>
                                        <p v-if="isCurrent_week == 1" class="txt-w-l">คุณสามารถร่วมโหวตอีกครั้ง <br>ในวันจันทร์ที่ 29 พฤษภาคม 2566 เวลา 10.00 น.  </p>
                                        <p v-if="isCurrent_week == 2" class="txt-w-l">คุณสามารถร่วมโหวตอีกครั้ง <br>ในวันจันทร์ที่ 5 มิถุนายน 2566 เวลา 10.00 น.  </p>
                                        <p v-if="isCurrent_week == 3" class="txt-w-l">คุณสามารถร่วมโหวตอีกครั้ง <br>ในวันจันทร์ที่ 12 มิถุนายน 2566 เวลา 10.00 น.  </p>
                                    </div>
                                    <div class="fw text-center box-content" v-else>
                                        <h6 class="txt-w-lg primary">ขณะนี้ระบบได้ทำการ<span style="display: inline-block;">ปิดโหวตแล้ว</span></h6>
                                        <p class="txt-w-l">กรุณาติดตามผลการคัดเลือก 20 แหล่งท่องเที่ยว <span style="display:inline-block">UNSEEN NEW CHAPTERS</span> <br><span style="display:inline-block">ได้ในวันที่ 27 มิถุนายน 2566</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="section-header nx-bg-pd-primary ">
            <nav class="navbar navbar-expand-xl ">
                <div class="container container-1360 position-relative">
                    <router-link to="/" class="navbar-brand" @click="handleClick('navbar','Home-logo')"><img src="@/assets/img/skin/logo.png" alt=""></router-link>
                    <div class="groupmenu-mobile" v-if="winW <= 1199">

                        <template v-if="profileData == '' || profileData == null">
                            <a v-if="campaign_Status != 4" class="button-user" href="javascript:void(0)" v-on:click="openPopAuth(false)">
                                <div class="box-welcome-img">
                                    <img src="@/assets/img/skin/icon-user.svg" class="svg" alt="">
                                </div>
                            </a>
                        </template>
                        <template v-else>
                            <div class="box-welcome-mobile">
                                <div class="box-welcome-img">
                                    <img src="@/assets/img/skin/icon-user.svg" class="svg" alt="">
                                </div>
                                <a class="btn btn-ctr-light py-0 px-4 rounded-pill box-welcome-credit-m" href="javascript:void(0)"
                                    @click="inIthistory == true ? [inIthistory = false, body.removeAttribute('style')] : [inIthistory = true, body.style.overflow = 'hidden'], handleClick('navbar','view-history')">
                                    <small>ดูประวัติการโหวต</small><span class="is-credit-m" v-if="campaign_Status == 0">{{ profileData.credit_vote }}</span><span class="is-credit-m" v-else>0</span>
                                </a>
                            </div>
                        </template>

                        <div class="btn-menu white" @click="menuClick()">
                            <div class="groupMenu"><span class="pan1"></span> <span class="pan2"></span> <span class="pan3"></span></div>
                        </div>
                    </div>
                    

                    <div class="navbar-collapse ">
                        <ul class="navbar-nav d-flex align-items-xl-center  " >
                            <li class="nav-item text-xl-center"><router-link   @click="menuClick(),handleClick('navbar','Home-menu')"  to="/">หน้าหลัก <br class="d-xl-block d-none">25 UNSEEN NEW CHAPTERS</router-link></li>
                            <!-- <li class="nav-item text-xl-center"><router-link  @click="menuClick(),handleClick('navbar','Attractions-menu')"  to="/attractions">แหล่งท่องเที่ยว<br class="d-xl-block d-none"> Unseen New Chapters</router-link></li> -->
                            <li class="nav-item text-xl-center"><router-link  @click="menuClick(),handleClick('navbar','Attractions-menu')"  to="/attractions">แหล่งท่องเที่ยว UNSEEN อื่นๆ</router-link></li>
                            <li class="nav-item text-xl-center"><router-link  @click="menuClick(),handleClick('navbar','Promotion-menu')" to="/promotion">โปรโมชั่น</router-link></li>
                            <li class="nav-item text-xl-center"><router-link  @click="menuClick(),handleClick('navbar','rules-reward-menu')" to="/rules-reward">กติกาและของรางวัล</router-link></li>
                            <li class="nav-item text-xl-center"><router-link  @click="menuClick(),handleClick('navbar','announcement-menu')" to="/announcement">ประกาศรางวัล</router-link></li>
                            <li class="nav-item d-xl-block d-none auth-button" v-if="campaign_Status != 4">
                                <template v-if="profileData == '' || profileData == null">
                                    <button class="btn btn-icon btn-ctr-secondary btn-radius" id="loginbtn" @click="handleClick('Auth','login-menu')"
                                        v-on:click="openPopAuth(false)"><span><img src="@/assets/img/skin/icon-login.svg"
                                                class="svg" alt=""><span>เข้าสู่ระบบ</span></span></button>
                                    <button class="btn btn-icon btn-ctr-secondary btn-radius ms-2" id="registerbtn" @click="handleClick('Auth','Register-menu')"
                                        v-on:click="openPopAuth(true)"><span><img src="@/assets/img/skin/icon-user.svg"
                                                class="svg" alt=""><span>ลงทะเบียน</span></span></button>
                                </template>
                                <template v-else>
                                    <div class="box-welcome ms-2 " v-if="winW >= 992">
                                        <div class="box-welcome-img">
                                            <img src="@/assets/img/skin/icon-user.svg" class="svg" alt="">
                                        </div>
                                        <div class="box-welcome-txt">
                                            <span class="box-welcome-txt-name"><b>คุณ {{ profileData.name }}</b></span>
                                            <a href="javascript:void(0)"
                                                @click="inIthistory == true ? [inIthistory = false, body.removeAttribute('style')] : [inIthistory = true, body.style.overflow = 'hidden'] , handleClick('navbar','view-history')"
                                                class="btn btn-icon btn-ctr-primary btn-radius txt-light">ดูประวัติการโหวต</a>
                                        </div>
                                        <div class="box-welcome-credit">
                                            <div class="box-welcome-credit-t gray-lighter"><span>สิทธิ์โหวตของคุณคือ</span></div>
                                            <div class="box-welcome-credit-c">
                                                <span class="box-welcome-credit-cnum">
                                                    <b v-if="campaign_Status == 0" >{{profileData.credit_vote }}</b>
                                                    <b v-else>0</b> 
                                                </span>
                                                <span class="box-welcome-credit-ctxt primary txt-w-xl">สิทธิ์</span></div>
                                        </div>
                                        <popupHistory v-if="inIthistory  && winW > 1199" :historyData="historyData"   v-on:close-logout="logout" ></popupHistory>
                                    </div>
                                </template>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>

        <transition name="fade">
            <authComponent v-if=" openPopAuthClick " v-on:load-api="handleVoteStart" v-on:login-success="errorAuth" :loginStatus=" loginStatus " :dataCheck=" dataCheck "
                :class=" [authClass, addClass] " v-on:close-login=" openPopAuthClick = false "></authComponent>

        </transition>

        <popupHistoryM v-if="inIthistory && winW <= 1199" :historyData="historyData" :profileData="profileData"  v-on:close-popup="handleHistory"  v-on:close-logout="logout" ></popupHistoryM>
        <loadApiComponent v-if="isloadeApi" class="loadpage-com loadApi " :class="isloadeApi == true ? isActive : isActive = '' "></loadApiComponent>

   
        <popupError v-if="voteError" :class="errorStatus"></popupError>





        <transition v-if="winW >= 992" name="fade">
            <div class="pop-history" v-if=" inIthistory ">
                <div class="popup-bg" @click=" inIthistory = false, body.removeAttribute('style') "></div>
            </div>
        </transition>
        <div class="wrapper">
            <router-view />
        </div>
        <div class="sec-foot">
            <div class="container container-1360 ">
                <div class="box-footer">
                    <div class="logo-footer">
                        <img src="https://previewcampaign.com/tatchallenge/html/assets/img/home/footer-logo.png" alt="">
                        <p>© 2023 Tourism Authority of Thailand All Right Reserved.</p>
                    </div>
                    <a href="https://lin.ee/3ZVJfnQ" target="_blank" rel="noopener noreferrer"><img class="fw" src="@/assets/img/home/line.png" alt=""></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import authComponent from '@/components/auth/authView.vue';
import popupHistory from '@/components/popup/popupHistory.vue';
import popupHistoryM from '@/components/popup/popupHistory.vue';
import loadApiComponent from '@/components/loadPageComponent.vue';


import popupError from '@/components/popup/popupError.vue';
import axios from 'axios';
import Cookies from 'js-cookie';
import Vue from 'vue';
import { cookieConsent } from './main';

import  VueGtag  from 'vue-gtag';
export default {
    components: {
        authComponent,popupHistory,loadApiComponent,popupHistoryM,popupError
    },
    data() {
        return {
            isloade: false,
            inIthistory: false,
            isActive:'active',
            isloadeApi:false,
            isAuthApi:false,
            showLogin: false,
            token: '',
            close_campaign: true,
            errorStatus:'',
            voteError:false,
            dataCheck: true,
            tokenStatus: Cookies.get('token'),
            userAssets:  sessionStorage.getItem('USER_INFO'),
            login_Status: Cookies.get('login_status'),
            is_campaign: Cookies.get('isCampaign'),
            pdpa_consent: Cookies.get('pdpa_consent_unseen'),
            isCurrent_week: 0,
            profileData: '',
            historyData: '',
            authClass: 'popup-auth',
            openPopAuthClick: false,
            loginStatus: false,
            winW : window.innerWidth,
            body: document.querySelector('body'),
            current_week: 1,
            campaign_Status: 0,
            api_getprofile: true,
            api_token: true,
            box_pdpa:true,
            intervalId: null,
            checkFocus: false,

        }
    },
    mounted() {
        this.scrollTo();
        if (document.querySelector('.cookie-bar') != null) {
            document.querySelector('.cookie-bar').style.display = 'block';
        }
        this.getProfile();
        if (this.login_Status == 1 ) {
            if (this.userAssets != null) {

                let decodedDataString = atob(this.userAssets);
                let decodedArray = JSON.parse(decodedDataString);

                this.profileData = decodedArray.profile;
                this.historyData = decodedArray.history;

                // // console.log(this.profileData);
                document.cookie = 'login_assets='+this.profileData.id;

                this.loginStatus = true;
                this.current_week = 'week_' + decodedArray.history.current_week;

                

            
                // window.addEventListener("storage", (event) => {
                //     if (sessionStorage.getItem('USER_INFO') !== null|| sessionStorage.getItem('USER_INFO') !== undefined) {
                //         let decodedDataString = atob(sessionStorage.getItem('USER_INFO'));
                //         let decodedArray = JSON.parse(decodedDataString);
                //         this.profileData = decodedArray.profile;
                //         this.historyData = decodedArray.history;
                //         this.current_week = 'week_' + decodedArray.history.current_week;
                //     }
                // });
                //  window.addEventListener("change", (event)  => {
                //     if (Cookies.get('login_status') == undefined) {
                //         sessionStorage.removeItem('USER_INFO');
                //         let decodedDataString = atob(sessionStorage.getItem('USER_INFO'));
                //         let decodedArray = JSON.parse(decodedDataString);
                //         this.profileData = decodedArray.profile;
                //         this.historyData = decodedArray.history;
                //         this.current_week = 'week_' + decodedArray.history.current_week;
                //         // // console.log('cookieStore change removeItem');
                //     }else{
                //         let decodedDataString = atob(sessionStorage.getItem('USER_INFO'));
                //         let decodedArray = JSON.parse(decodedDataString);
                //         this.profileData = decodedArray.profile;
                //         this.historyData = decodedArray.history;
                //         this.current_week = 'week_' + decodedArray.history.current_week;
                //     }
                // });

            }else{
                this.updateData();
            }
        }else{
            sessionStorage.removeItem('USER_INFO');
        }

       
       
        this.getToken();
        setTimeout(() => {
            this.getToken();
        }, 100);

        this.winW = window.innerWidth;
        window.addEventListener('resize', () => {
            this.winW = window.innerWidth;
        });


        setTimeout(() => {
            this.scrollTo();
        }, 500);

        // window.onload = () => {
        //     this.isCampaign();
        // }

        this.isCampaign();
        // setInterval(() => {
        //     this.isCampaign();
        // }, 10000);
        this.handleWindowFocus();
        // window.addEventListener('focus', this.handleWindowFocus);
        // window.addEventListener('blur', this.handleWindowBlur);
     
    },
    created() {
        this.scrollTo();
    //    setTimeout(() => {
    //     window.addEventListener('focus', this.handleWindowFocus);
    //     window.addEventListener('blur',  this.handleWindowBlur);
    //    }, 200);
    },

    beforeUnmount() {
      // Clear interval and remove event listeners before component is destroyed
      clearInterval(this.intervalId);
      window.removeEventListener('focus', this.handleWindowFocus);
      window.removeEventListener('blur', this.handleWindowBlur);
    },
    watch: {
        '$route'(to, from) {
        if (to.name !== from.name) {
            if (this.login_Status == 1 ) {
                this.updateData();
            }
            this.scrollTo();
        }
       
        },
    },
    methods: {
        handleWindowFocus() {
            
            // if(this.checkFocus == false){
            //     this.isCampaign();
            //     this.checkFocus = true;
            // }
         
            // Start interval when window is in focus
            // this.intervalId = setInterval(this.isCampaign, 5000);
        
        },
        handleWindowBlur() {
            // this.checkFocus = false;
            // console.log('blur');
            // Clear interval when window is out of focus
            // clearInterval(this.intervalId);
        },
        
        scrollTo(){
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth' // Add smooth scrolling animation
            });
        },
        closeCookie(){
            setTimeout(() => {
                document.querySelector('.cookie-bar').style.display = 'none';
            }, 400);
        },

        pdpa_acceptAll() {
            // Set active 'pdpa' cookie with 3-month expiration
            const expirationDate = new Date();
            expirationDate.setMonth(expirationDate.getMonth() + 3);
            const cookieValue = 'active; expires=' + expirationDate.toUTCString();
            document.cookie = 'pdpa_consent_unseen=' + cookieValue;
            
            this.pdpa_consent = Cookies.get('pdpa_consent_unseen');

            cookieConsent();

        },

            // initGoogleTagManager() {
            //     this.$gtag.config('G-NEX569WVWF');

            //     // Additional configurations if needed
            //     this.$gtag.event('page_view'); // Optional: Send a page view event
            // },

        handleClick(name,value) {
            if (Cookies.get('pdpa_consent_unseen') == 'active') {
                this.clickTracking(name,value);
            }
        },
        // clickTracking(name,value) {
        //     const track_name = name;
        //     const track_value = value;
        //         this.$gtag.event('click', {
        //         event_category: track_name,
        //         event_label: track_value,
        //         value: 1
        //  });

        //     // console.log(this.$gtag);
        // },
        updateUserAssets(){
                let decodedDataString = atob(this.userAssets);
                let decodedArray = JSON.parse(decodedDataString);

                this.profileData = decodedArray.profile;
                this.historyData = decodedArray.history;

                this.loginStatus = true;
                this.current_week = 'week_' + decodedArray.history.current_week;
        },
        metaDefault(){
            document.title = 'Unseen New Chapters ปักหมุดใหม่ เปิดไทยมุมต่าง';
            document
                .querySelector('meta[name="keywords"]')
                .setAttribute("content",'Unseen New Chapters ปักหมุดใหม่ เปิดไทยมุมต่าง');
            document
                .querySelector('meta[name="description"]')
                .setAttribute("content", 'ร่วมโหวตแหล่งท่องเที่ยวเมืองไทยในมุมใหม่ เพื่อเป็นหนึ่งใน 20 แหล่งท่องเที่ยวสุด Unseen แหล่งใหม่ของเมืองไทย ผู้ร่วมสนุกมีสิทธิ์ลุ้นรับของรางวัลถึง 2 ต่อ ร่วมสนุกได้ตั้งแต่วันนี้ - 18 มิถุนายน 2566');

            // Set the Open Graph metadata for Facebook sharing.
            document
                .querySelector('meta[property="og:title"]')
                .setAttribute("content",'Unseen New Chapters ปักหมุดใหม่ เปิดไทยมุมต่าง');
            document
                .querySelector('meta[property="og:type"]')
                .setAttribute("content", "website");
            document
                .querySelector('meta[property="og:url"]')
                .setAttribute("content", window.location.href);
            document
                .querySelector('meta[property="og:image"]')
                .setAttribute("content", '');
            document
                .querySelector('meta[property="og:description"]')
                .setAttribute("content", 'ร่วมโหวตแหล่งท่องเที่ยวเมืองไทยในมุมใหม่ เพื่อเป็นหนึ่งใน 20 แหล่งท่องเที่ยวสุด Unseen แหล่งใหม่ของเมืองไทย ผู้ร่วมสนุกมีสิทธิ์ลุ้นรับของรางวัลถึง 2 ต่อ ร่วมสนุกได้ตั้งแต่วันนี้ - 18 มิถุนายน 2566');
        },

        updateData() {
            this.getProfile();
            if (sessionStorage.getItem('USER_INFO')) {
                const decodedDataString = atob(sessionStorage.getItem('USER_INFO'));
                const decodedArray = JSON.parse(decodedDataString);
                this.profileData = decodedArray.profile;
                this.historyData = decodedArray.history;
                this.current_week = 'week_' + decodedArray.history.current_week;
                // // console.log('updateData');
            }
        },
        menuClick() {
            if(this.winW <= 1199){
                const menuBody = document.querySelector('body');
                if (menuBody.classList.contains('menuClick')) {
                    menuBody.classList.remove('menuClick');
                } else {
                    menuBody.classList.add('menuClick');
                }
            }
            
        },
        closePopUpCam() {
            const menuBody = document.querySelector('body');
            
            if (menuBody.classList.contains('hide')) {
                menuBody.classList.remove('hide');
            }else{
                menuBody.classList.add('hide');
            }
        },
        handleHistory(status){
            const closeHistory = document.querySelector('.box-history');
            if (!status) {
                closeHistory.classList.remove('active');
            }

            setTimeout(() => {
                this.inIthistory = status
            }, 500);
        },
        handleVoteError(Error){
            this.voteError =  Error;
        },
        handleVoteSuccess(success) {
            if (success) {
                this.isloadeApi = success;
              
            } else {
                this.isloadeApi = success
            }
        },
        handleVoteStart(success){
            if (success) {
                this.isloadeApi = success
            } else {
                this.isloadeApi = success
                
            }
        },
        errorAuth(success, type){
            if (success) {
                this.voteError = success;
                this.errorStatus = type;
               
            } else {
                this.voteError = success;
                
            }
        },
        logout(status) {
            if (status) {
                // console.log('logout');
                if (Cookies.get('login_status') !== undefined) {
                    // Remove the "USER_INFO" cookie
                    window.location.reload();
                    Cookies.remove('login_assets');
                    Cookies.remove('login_status');
                    sessionStorage.removeItem('USER_INFO');
                    // Reload the page
                    this.profileData = '';
                    this.historyData = '';
                    this.inIthistory = false;
                    this.body.removeAttribute('style');
                    
                }
            }
        },
        async getToken() {
            try {
                if (this.api_token) {
                    const tokenResponse = await axios.get('https://uat.unseennewchapters.com/nx/API/get_token');
                    this.token = tokenResponse.data.hash;
                    Cookies.set('token', this.token , { expires: 90 })
                    if (this.login_Status == 1 ) {
                        if (this.userAssets != null) {
                            this.getProfile();
                        }
                    }
                }
            } catch (error) {
                this.api_token = false
            }
        },

        async isCampaign() {
            
            this.is_campaign = Cookies.get('isCampaign');
            try {
                // const campaignStatus = await axios.get('https://uat.unseennewchapters.com/nx/API/is_campaign');
                const campaignStatus = [{
                    current_week: 4,
                    rs: 4,
                    status: "end"
                }]
                // console.log('test',campaignStatus);
                this.campaign_Status = campaignStatus[0].rs;
               
                if(campaignStatus[0].status == 'pre'){
                    this.body.setAttribute('data-pre-campaign', '');
                    this.body.removeAttribute('data-end-campaign', '');
                    
                    return 'pre';

               }else if(campaignStatus[0].status == 'end'){
                    this.body.setAttribute('data-end-campaign', '');
                        if (campaignStatus[0].rs > 0) {
                            this.body.setAttribute('data-close-campaign', '');

                            document.querySelector('.close-campaign').classList.add('show')
                            document.querySelector('.close-campaign').style.display = 'block';
                            // this.getProfile();
                            this.isCurrent_week = campaignStatus[0].rs;
                            if(this.is_campaign == null ){
                                Cookies.set('isCampaign', 1 , { expires: 1 })
                            }

                            return campaignStatus[0].rs;
                            
                    }else if(campaignStatus[0].rs == 0){
                        
                            this.body.classList.remove('show')
                            document.querySelector('.close-campaign').style.display = 'none';
                            this.body.removeAttribute('data-close-campaign');
                            if(this.is_campaign == 1 ){
                                Cookies.remove('isCampaign');
                                window.location.reload();
                            }

                            return campaignStatus[0].rs;

                    }
                    
                    return 'end'
               }else{
                this.body.removeAttribute('data-pre-campaign');
                if (campaignStatus[0].rs > 0) {
                        this.body.setAttribute('data-close-campaign', '');

                        document.querySelector('.close-campaign').classList.add('show')
                        document.querySelector('.close-campaign').style.display = 'block';
                        // this.getProfile();
                        this.isCurrent_week = campaignStatus[0].rs;
                        if(this.is_campaign == null ){
                            Cookies.set('isCampaign', 1 , { expires: 1 })
                        }
                        return campaignStatus[0].rs;
                        
                }else if(campaignStatus[0].rs == 0){
                    
                        this.body.classList.remove('show')
                        document.querySelector('.close-campaign').style.display = 'none';
                        this.body.removeAttribute('data-close-campaign');
                        if(this.is_campaign == 1 ){
                            Cookies.remove('isCampaign');
                            window.location.reload();
                        }

                        return campaignStatus[0].rs;

                }
               }
              
            } catch (error) {
                // // console.error(error);
            }
        },

        async isActiveCurrent_week() {
            try {
                const campaignStatus = await axios.get('https://uat.unseennewchapters.com/nx/API/is_campaign');
                    return campaignStatus.data.current_week;
            }catch (error) {
                // // console.error(error);
            }
        },
        async getProfile() {
            try { 
                    const user_id =  Cookies.get('login_assets');
                    if (user_id != undefined) {
                        const formData = new FormData();
                        formData.append('member_id',  user_id);
                        formData.append('token', this.tokenStatus );
                        const response = await axios.post('https://uat.unseennewchapters.com/nx/API/getProfile', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': `Bearer ${this.tokenStatus}`
                            }
                        });
                        if (response.data != '') {
                            if (response.data.rs == true) {
                                if (this.login_Status == 1) {
                                    let decodedDataString =  atob(response.data.userAssets);
                                    let decodedArray = JSON.parse(decodedDataString);
                                    this.userProfile = decodedArray.profile;
                                    this.userHistory = decodedArray.history;
                                    const dataResponse = response.data.userAssets;
                                    sessionStorage.setItem('USER_INFO', dataResponse);
                                    this.userAssets = response.data.userAssets;
                                    this.updateUserAssets();
                                }
                            }else{
                                // if(Cookies.get('login_status') != null || Cookies.get('login_status') != undefined){
                                //     this.logout(true);
                                //     // console.log('response.data.rs');
                                // }
                            }
                        }   
                    }
                
            } catch (error) {
                // this.logout(true);
                this.api_getprofile = false
            }
        },

        
        openPopAuth(data) {
            this.dataCheck = data;
            this.openPopAuthClick = true;
        },
        removeClass() {
            const popup = document.querySelector('.popup-login');
            popup.classList.remove('active');

            if (popup.classList.remove('active')) {
                popup.classList.remove('popup-register-form');
                popup.classList.remove('popup-login-form');
            }
            this.openPopAuthClick = false;
        },

        svg() {
            document.querySelectorAll("img.svg").forEach(function (img) {
                var imgID = img.getAttribute("id");
                var imgClass = img.getAttribute("class");
                var imgURL = img.getAttribute("src");
                var xhr = new XMLHttpRequest();
                xhr.open("GET", imgURL, true);
                xhr.onreadystatechange = function () {
                    if (this.readyState === 4 && this.status === 200) {
                        var data = xhr.responseXML;
                        var svg = data.querySelector("svg");
                        if (typeof imgID !== "undefined") {
                            svg.setAttribute("id", imgID);
                        }
                        if (typeof imgClass !== "undefined") {
                            svg.setAttribute("class", imgClass + " replaced-svg");
                        }
                        svg.removeAttribute("xmlns:a");
                        if (
                            !svg.getAttribute("viewBox") &&
                            svg.getAttribute("height") &&
                            svg.getAttribute("width")
                        ) {
                            svg.setAttribute(
                                "viewBox",
                                "0 0 " +
                                svg.getAttribute("height") +
                                " " +
                                svg.getAttribute("width")
                            );
                        }
                        // img.parentNode.replaceChild(svg, img);
                        if (img.parentNode) {
                            img.parentNode.replaceChild(svg, img);
                        }
                    }
                };
                xhr.setRequestHeader(
                    "Content-Type",
                    "application/x-www-form-urlencoded; charset=UTF-8"
                );
                xhr.send();
            });
        }
        
    },
    computed: {
        routeName() {
            return this.$route.name;
        },
        addClass() {
            if (this.dataCheck === true) {
                return 'active popup-register-form ';
            } else if (this.dataCheck === false) {
                return 'active popup-login-form ';
            } else {
                return '';
            }

        }
    },
};
</script>

<style>

.loadpage-com{opacity: 0; transition:all ease-in-out .3s;}
.loadpage-com.active{opacity: 1;}
[data-close-campaign] .card-like {color: #b1b1b1 !important;background-color: #e2e2e2 !important;border: 2px solid #e2e2e2 !important;pointer-events: none;}
[data-close-campaign] .card-like  svg {
    fill: #b1b1b1 !important;
}
[data-close-campaign] .card-like svg .path-1,[data-close-campaign] .card-like svg .path-2{
    fill: #b1b1b1 !important;
}
[data-close-campaign] .isvote .itemIsVote .card-like svg path{fill: #b1b1b1 !important;}


[data-pre-campaign] .box-welcome-mobile,

[data-pre-campaign] .close-btn-pre,
[data-pre-campaign] .button-user,
[data-pre-campaign] .tab-my-rights,
[data-pre-campaign] .sec-home-top-rank,
[data-pre-campaign] .auth-button,
[data-pre-campaign] .tab-list a span.region-voted,
[data-pre-campaign] .card-like{display: none !important;}
[data-pre-campaign]  .card-cont{width: 100%;}



[data-end-campaign] .register-m,
[data-end-campaign] .close-btn-pre,
[data-end-campaign] .sec-home-top-rank,
[data-end-campaign] .tab-my-rights,
[data-end-campaign] .auth-button #registerbtn,
[data-end-campaign] .tab-my-rights,
[data-end-campaign] .is-credit-m,
[data-end-campaign] .box-welcome-credit,
[data-end-campaign] .tab-list a span.region-voted,
[data-end-campaign] .auth-button,
[data-end-campaign] .button-user,
[data-end-campaign] .card-like{display: none !important;}
[data-end-campaign]  .card-cont{width: 100%;}

#loginbtn:hover{color: #fff;background-color: #EC268F;}
#loginbtn:hover svg{fill: #fff;}


#registerbtn{color: #fff;background-color: #EC268F;}
#registerbtn svg{fill: #fff;}

#registerbtn:hover{color: #EC268F;background-color: #fff;}
#registerbtn:hover svg{fill: #EC268F;}

.pop-history {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.box-history .sec-tab {
    padding: 20px 20px 10px 20px;
}

.box-history .tab-list.active:before {
    height: calc(100% + 20px);
}

.box-history .tab-list {
    min-height: 32px;
    padding: 0 15px;
    margin: 0;
}

.box-footer a{width: 250px;}

.box-history .tab-list:last-child {
    margin-right: 0;
}

.box-history .list-item {
    display: flex;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #E6E6E6;
    align-items: center;
}

.box-history .item-img {
    width: 70px;
    border-radius: 10px;
    overflow: hidden;
}

.box-history .item-cont {
    width: calc(100% - 70px);
    padding-left: 20px;
}

.list-tab {
    display: none;
}

.list-tab.active {
    display: block;
}

.tab-list-item {
    padding: 0 20px;
}

.popup-auth {
    z-index: -2;
    transition: all ease-in-out .5s;
    opacity: 0;
}

.popup-auth.active {
    z-index: 1001;
    opacity: 1;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.wrapper {
    padding-top: 54px;
    min-height: calc(100vh - 0px);
    overflow: hidden;
}

.section-header {
    float: left;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
}

.navbar-brand {
    width: 80px;
    position: absolute;
    top: -8px;
    left: 0;
    transition: none;
}

.navbar-brand img {
    width: 100%;
}

.navbar-brand {
    background-color: #EC268F;
    padding: 10px;
    border-radius: 0 0 10px 10px;
}

.navbar .navbar-nav {
    float: right;
    margin-left: auto;
}

.navbar .nav-item {
    margin: 0 20px;
    line-height: 1.2;
}

.navbar .nav-item > a {
    color: #FFE7E7;
    /* color: rgba(255, 255, 255, 0.5); */
    font-weight: 300;
}

.navbar .nav-item > a:hover{transition:all ease-in .3;color: #fff;}
.navbar .nav-item .router-link-exact-active {
    color: #fff;font-weight: 700;
}

.sec-foot {
    background-color: #EAEAEA;
    padding: 10px 0;
    float: left;
    width: 100%;
}

.sec-foot .logo-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sec-foot .logo-footer img {
    width: 40px;
    height: 40px;
    float: left;
    margin-right: 10px;
}

.sec-foot .logo-footer p {
    float: left;
    font-size: 0.8em;
}







/* ========= COOKIE ======== */
.cookie-bar{position: fixed;bottom: 0;left: 0;width: 100%;background-color: #fff;z-index: 12;box-shadow:0 0 5px 0 rgba(0,0,0,.1);display:none;text-transform: capitalize;opacity: 0;transition: all ease-in-out .5s;}
.cookie-bar.active{opacity: 1;}
.cookie-bar .cookie-body{padding: 20px;position: relative;}
.cookie-bar .cookie-body .cookie-title{color: #000000; font-size: 1.2em;font-weight: bold ;}
.cookie-bar .cookie-body .cookie-title.cookie-title-lang{display:flex;align-items: center;}
.cookie-bar .cookie-body .cookie-lang {width: auto;float: left;margin-left: 10px; }
.cookie-bar .cookie-body .cookie-text{}
.cookie-bar .cookie-body .cookie-text ul{padding-left: 20px;list-style: disc;}
.cookie-bar .cookie-body .cookie-sub-text a{color: var(--color-primary);text-decoration: underline;}
.cookie-bar .cookie-body .cookie-button {width: 100%;height: 100%;float: left;position: relative;}
.cookie-bar .cookie-body .cookie-button button{  text-transform: capitalize;  outline: none;}
.cookie-bar .cookie-body .cookie-button  button.btn.btn-accept{background-color: var(--color-primary);color: #fff;position: absolute;right: 20px;bottom: 0;border-radius: 100px;}
.cookie-bar .cookie-body .cookie-button  button.btn.btn-accept:hover{background-color: var(--color-primary-dark);color: #fff;}
.cookie-bar .cookie-body .cookie-button .close{position: absolute;top: 0;right: 20px;font-size: 1em;}
.cookie-bar .cookie-body .cookie-button .cookie-lang{}
.cookie-bar .cookie-body .setting-cookie{color: var(--color-primary);    }
.cookie-ot-floating-button__open {display: none; position: fixed;bottom: 30px;left: 5px;z-index: 13;cursor: pointer;}
.cookie-ot-floating-button__open .material-symbols-outlined {color: #fff;font-size: 40px;width: 60px;height: 60px;background-color: var(--color-primary);z-index: 13;box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);border-radius: 100%;display: flex;align-items: center;justify-content: center;cursor: pointer;}
.cookie-lang label{margin: 0px 15px;}

.box-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


@media (max-width: 1199.98px){
  
    
    .navbar-collapse {
        transition: all ease-in-out .5s;
        height: 0;
        position: absolute;
        top: 48px;
        background-color: #fff;
        width: 100%;
        overflow: hidden;
        left: 0;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-right: auto;
        margin-left: auto;
    }
    .section-header .container-1360{max-width: 100%;}
    .menuClick .navbar-collapse {height: calc(100vh - 48px);}
    .navbar .nav-item > a {
        color: #7C7C7C;
        font-size: 1.2em;
    }
    .navbar .navbar-nav {
        width: 100%;
        padding-top: 60px;
    }
    
    .navbar .nav-item{margin-bottom: 20px;margin-left: 0;}
    .navbar .nav-item .router-link-exact-active{color: var(--color-primary);}
    .navbar-brand{z-index: 1;left: calc(var(--bs-gutter-x) * .5);}
    	/* = Mobile = */
	.btn-menu { display:flex;  right:30px; align-items:center; cursor:pointer; z-index:12;padding: 12px 0;}
	.btn-menu .groupMenu {margin: 0 auto;width: 26px; cursor:pointer; height:16px; position:relative; float:right;}
	.btn-menu .groupMenu span { position:absolute;width: 26px;height: 2px;background-color: #fff;float: left;margin-bottom: 4px; overflow:hidden;-webkit-transition:ease .4s; transition:ease .4s; }
	.btn-menu .groupMenu span:last-child {margin-bottom: 0px;}
	.btn-menu .groupMenu .pan1 { top:0px; left:0px; transform-origin:center center;-webkit-transform-origin:center center;}
	.btn-menu .groupMenu .pan2 { top:7px; left:0px; transform-origin:center center;-webkit-transform-origin:center center; }
	.btn-menu .groupMenu .pan3 { top:14px; left:0px; transform-origin:center center;-webkit-transform-origin:center center;}
	.btn-menu .btn-menutext { color:#fff; float:right; text-transform:uppercase; font-size:10px; padding-top:3px; margin-right:15px; letter-spacing:1px;}
	.btn-menu:hover span:before { left:0px; }
	.closeMenu { position:absolute; top:20px; right:20px; width:50px; height:50px; z-index:-1; cursor:pointer;}
	.menuClick .groupMenu .pan1 { left: 0; transform:rotate(45deg);-ms-transform:rotate(45deg);-webkit-transform:rotate(45deg); top:6px; z-index:2; height:2px;}
	.menuClick .groupMenu .pan2 { left: 0; width:0px; height:0px;}
	.menuClick .groupMenu .pan3 { left: 0; transform:rotate(-45deg);-ms-transform:rotate(-45deg);-webkit-transform:rotate(-45deg); top:6px; z-index:2; height:2px;}
	.menuClick .closeMenu { z-index:12;}
	.menuClick .btn-menu { z-index:12;}

    .groupmenu-mobile{display: flex;align-items: center;width: 100%;justify-content: end;}
    .groupmenu-mobile .box-welcome-img{ width: 35px;height: 35px;}
    .groupmenu-mobile a:has(.box-welcome-img){margin-right: 10px;}

    .box-welcome-mobile{display: flex; align-items: center;margin-right: 20px;}
    .box-welcome-credit-m{position: relative;}
    .box-welcome-credit-m span {
        position: absolute;
        top: -10px;
        width: 15px;
        height: 15px;
        font-size: 14px;
        background-color: #EC268F;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        right: 0;
    }
   
}
.close-campaign{z-index: -1 !important;opacity: 0;transition:all .5s ease-in-out; display: none;}
[data-close-campaign] .close-campaign{z-index: 1002 !important;opacity: 1;}
.show[data-close-campaign] .close-campaign{}
.hide[data-close-campaign] .close-campaign{opacity: 0; z-index: -1 !important;}
.close-campaign .popup-container{border-radius: 20px;}



@media (max-width: 991.98px){
    .close-campaign .popup-container{width: 90vw;}
}
@media (max-width: 767.98px){
    .close-campaign .popup-container{width: 85vw;}

    	/*
	 |*******************************************************************************************
	 | COOKIE
	 |*******************************************************************************************
	*/
	.cookie-bar .cookie-body .cookie-button{display: flex;justify-content: space-between; align-items: center;padding-top: 20px;}
	.cookie-bar .cookie-body .cookie-button .close {position: relative;top: 0;right: 0;float: left;text-decoration: underline;}
	.cookie-bar .cookie-body .cookie-button button.btn.btn-accept{position: relative;min-width: 200px;float: left;right: 0;}
	.cookie-popup {font-size: 3.2vw;}
	.cookie-popup .cookie-container {max-width: 90%;padding: 20px;}
	.cookie-popup .cookie-card{padding:20px}
	.cookie-popup .cookie-title.cookie-title-lang{flex-wrap: wrap;}
	.cookie-popup .cookie-title.cookie-title-lang span{width: 100%;order: 2;font-size: 5vw;}
	.cookie-popup .cookie-card-head span{font-size: 4vw;}
	.cookie-popup .cookie-pop-button{order: 1;width: 100%;justify-content: flex-end;}
	.cookie-popup  .cookie-button {flex-wrap: wrap;padding-top: 10px;}
	.cookie-popup  .cookie-button .btn{width: 100%;margin-bottom: 10px;margin-right: 0 !important;}
	.cookie-popup  .cookie-button .btn:last-child{margin-bottom: 0;}
	.cookie-popup .cookie-body {float: left;height: 100%;}

}

@media (max-width: 575.98px){
    .navbar-brand{width: 65px;}
    .close-campaign .popup-container{width: 90vw;}
    .close-campaign .popup-close{right: 10px;top: 10px;}
    [data-close-campaign] .close-campaign .popup-body{padding: 65px 5px 45px;}

    [data-pre-campaign] .sec-home-campaign{margin-bottom: 40px;}
    [data-end-campaign] .sec-home-campaign{margin-bottom: 40px;}

    /* .box-footer{flee} */
}
</style>
