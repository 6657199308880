<template>
    <div class="group-bg-main">
        <div class="st sec-banner-main bg-banner pd bg-prmotion mb-0">
            <div class="container container-1360">
                <div class="position-relative">
                    <div class="box-title box-title-banner">
                        <h1 class="font-display mb-0 text-uppercase"><span class="gd-hl">Unseen Deals</span></h1>
                        <h2><b>โปรโมชั่นสุดพิเศษ<br>พร้อมให้ออกเดินทางท่องเที่ยว<br>UNSEEN ทั่วไทย</b></h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="st section-promotion mb-5">
            <div class="container">
                <div class="row g-4">
                    <div class="col-12 col-md-4 col-lg-3" v-for="item in paginatedItems" :key="item.id">
                        <div class="card-promotion" @click="inItPromotion(item.name,item.description,item.image ,item.link_out)">
                            <div class="card-promotion-image">
                                <img :src="require(`../assets/img/promotion/${item.image}`)" alt="" class="fw">
                            </div>
                            <div class="card-promotion-text text-start">
                                <div class="group-promotion">
                                    <div class="card-pro-title">{{ item.name }}</div>
                                </div>
                                <div class="card-pro-group">
                                    <a href="javascript:void(0)" class="btn-icon">
                                        <span><span class="">ดูรายละเอียด</span>
                                            <img class="svg" src="@/assets/img/skin/icon-arrow-right-primary.svg" alt="">
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                       <div class="pagination mt-4">
                            <div class="pageination-group">
                                <div class="pagination-item" v-for="page in pages" :key="page">
                                    <button  v-if="page === 1 || page === currentPage || page === pageCount || Math.abs(page - currentPage) <= 1" @click="currentPage = page" :class="{ active: page === currentPage }">
                                        {{ page }}
                                    </button>
                                    <span v-else-if="Math.abs(page - currentPage) === 2">...</span>
                                </div>
                        </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <loadPageComponent v-if="isloade" class="loadpage-com" :class="isloade == true ? isActive : isActive = '' "></loadPageComponent>
    <popupPromotion v-if="isPromotion" v-on:close-popup="isPromotion = false" :promotionArray="promotionArray"></popupPromotion>
</template>

<script>
import axios from 'axios';
// import { mapState } from 'vuex';
import loadPageComponent from '@/components/loadPageComponent.vue';
import popupPromotion from '@/components/popup/popupPromotion.vue';
export default {
    /* eslint-disable */
    components: { loadPageComponent,popupPromotion },
    data() {
        return {
            promotions: [],
            itemsPerPage: 12,
            currentPage: 1,
            isActive:'active',
            isloade: false,
            promotionArray:[],
            isPromotion:false
        }
    },

    mounted() {
        document.title = 'โปรโมชั่นสุดพิเศษ | Unseen New Chapters ปักหมุดใหม่ เปิดไทยมุมต่าง';
        window.onload = () => {
            this.$root.svg();
        }
        // window.addEventListener("load", () => this.getPromotions());
        this.getPromotions()

        setTimeout(() => {
            var isloadeLoop =  setInterval(() => {
                // // // console.log(this.travelListZone.zones);
               
               try {
                    if(this.promotions != ''){
                        if (document.querySelectorAll('.loadpage-com').length !== 0) {
                            document.querySelector('.loadpage-com').classList.remove('active')
                        }
                            setTimeout(() => {
                                this.isloade = false;
                                clearInterval(isloadeLoop);
                            }, 200);
                    }else{
                        this.isloade = true;
                    }
               } catch (error) {
                    if (document.querySelectorAll('.loadpage-com').length !== 0) {
                        document.querySelector('.loadpage-com').classList.remove('active')
                    }
                    setTimeout(() => {
                        this.isloade = false;
                        clearInterval(isloadeLoop);
                    }, 200);
               }
                
            }, 1000);
        }, 500);
        
    },
    methods: {
        async getPromotions() {
            this.isloade = true
                try {
                    const response = await axios.get(`https://uat.unseennewchapters.com/nx/API/get_promotions`);
                    if (response.data.rs == true) {
                        this.promotions = response.data.data;

                    }
                    
                } catch (error) {

                    this.isloade = true
                }
        },

        inItPromotion(name,description,image ,link_out){
            this.isPromotion = true;
            this.promotionArray = [{
                name: name,
                description:description,
                image:image,
                link_out:link_out,
            }]
        }

    },

    computed: {
        paginatedItems() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.promotions.slice(startIndex, endIndex);
        },
        pageCount() {
            return Math.ceil(this.promotions.length / this.itemsPerPage);
        },
        pages() {
            let pages = [];
            for (let i = 1; i <= this.pageCount; i++) {
                pages.push(i);
            }
            // console.log(pages);
            return pages;
        },
    },
}
</script>


<style scoped>
.bg-prmotion {
    background-image: url(../assets/img/promotion/bg-promotion-d.jpg);
}

.card-promotion-image {width: 100%;overflow: hidden;border-radius: 10px;}
.card-promotion .card-promotion-image img{transition: all ease-in-out .4s;}
.card-promotion:hover .card-promotion-image img{transform: scale(1.1);}
.card-promotion {
    position: relative;
    height: 100%;
    border: 1px solid #C3C3C3;
    width: 100%;
    float: left;cursor: pointer;
}

.card-promotion-text {
    padding-top:10px ;
    width: 100%;
    float: left;
}

.card-pro-title {
    font-size: 1em;
    font-weight: 600;
    line-height: 1;
    padding-bottom: 40px;
    /* overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1; */
}

.card-pro-desc {
    font-size: 1em;
    font-weight: 300;
    /* overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1; */
}

.card-pro-group {
    font-size: 0.9em;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 10px;
}

.card-group-location {
    color: #A6A6A6;
}
.btn-icon > span{    justify-content: flex-start;}
.card-pro-group .btn-icon {
    color: var(--color-primary);
    font-weight: 300;
}

.card-pro-group .btn-icon>span img,
.card-pro-group .btn-icon>span svg {
    fill: var(--color-primary);height: 10px;
}


.pagination{justify-content: center;}
.pageination-group {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F5;
    border-radius: 100px;
    padding: 5px 60px;
}
    .pageination-group button{min-width: 33px;height: 33px;background-color: transparent;border: 1px solid transparent;color:#7C7C7C; font-weight: 500;}
    .pageination-group .pagination-item button.active {background-color: #EC268F;border: 1px solid #EC268F;border-radius: 5px;color: #fff;}


    @media (max-width: 991.98px){
        .card-pro-title{font-size: 1em;}
        .card-pro-desc { font-size: 0.9em;}
    }
    @media (max-width: 767.98px){
        .card-promotion {display: flex;}
        .card-promotion-image {width: 50%;overflow: hidden;border-radius: 10px;}
        .card-promotion-text {padding-left: 20px;width: calc(50% - 20px);display: flex;flex-wrap: wrap;justify-content: space-between;padding-top: 0;}
        .card-pro-group{padding-top: 0;align-items: flex-end;}
        .group-promotion{    width: 100%;float: left;height: calc(100% - 23px);}
        .bg-prmotion{height: auto;}
    }
</style>
