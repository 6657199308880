<template>
    <div>
        
        <div class=" group-bg-main st-banner">
            <div class="st sec-home-main  pd pb-0">
                <div class="ele ele-main-kv-cloud"><img class="fw" src="@/assets/img/home/cloud-hero-banner.png" alt=""></div>
                <div class="ele ele-main-kv-pin"><img class="fw" src="@/assets/img/home/kv-pin.png" alt=""></div>
                <div class="container container-1360">
                    <div class="position-relative pd-b">
                        <div class="banner-cont">
                            <div class="cont-l text-center">
                                <img class="logo-kv" src="@/assets/img/skin/logo.png" alt="">
                                <div class="font-display">
                                    <h3 class="primary w-auto banner-t-1">ททท. ชวนติดตาม</h3>
                                </div>
                                    <h4 class="txt-w-md banner-t-2"><b>แหล่งท่องเที่ยว<br>Unseen New Chapters<br>ปักหมุดมุมใหม่ เปิดไทยมุมต่าง</b></h4>
                                <p class="text-light">ในวันอังคารที่ 27 มิถุนายน 2566 <span style="display: inline-block;">พร้อมกันทั่วประเทศ</span> </p>
                                <router-link  v-if="winW >= 1200 || winW < 758"  @click="handleClick('hero banner','attractions btn')" class="btn btn-ctr-primary rounded-pill mt-2" to="/attractions">ดูแหล่งท่องเที่ยว Unseen New Chapters</router-link>
                                
                            </div>
                            <div class="cont-r">
                                <div class="box-banner-slide box-bannervideo">
                                    <div class="swiper banner_slide">
                                        <div class="swiper-wrapper" data-state="1">
                                            <div class="swiper-slide video-youtube-0">
                                                <div class="box-connt-baner">
                                                    <div class="box-video">
                                                        <div class="ganeral-video">
                                                            <div class="video-container inactive-state click-to-play-video auto-init"  :class="[hidePreview ? 'hidePreview' : '']" ref="player">
                                                                <div class="video-put" data-video="oX8Yq0Iqjcg" data-code="1" section_type="video">
                                                                    <div id="player_ganeral_1_video" class="player_ganeral " ref="iframe"></div>
                                                                </div>
                                                                <div class="video-space d-flex h-100">
                                                                    <img class="float-left w-100" style="object-fit: cover;" src="@/assets/img/home/banner-video.jpg">
                                                                </div>
                                                                <div class="videoplay-icon"><img class="" src="@/assets/img/skin/btn-play.svg"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="swiper-slide">
                                                <div class="box-connt-baner">
                                                    <img src="@/assets/img/home/banner-image-1.jpg" alt="">
                                                </div>
                                            </div>
                                            <div class="swiper-slide">
                                                <div class="box-connt-baner">
                                                    <img src="@/assets/img/home/banner-image-2.jpg" alt="">
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="swiper-pagination"></div>
                                </div>
                            </div>
                        </div>
                        <div class="fw py-2 text-center" v-if="winW < 1200 && winW > 767">
                            <router-link  @click="handleClick('hero banner','attractions btn')" class="btn btn-ctr-primary rounded-pill mt-2" to="/attractions">ดูแหล่งท่องเที่ยว Unseen New Chapters</router-link>
                        </div>
                    </div>

                    <div class="st sec-home-campaign bg-gd">
                        <div class="row align-items-center justify-content-center">
                            <div class="col-12 col-md-9 col-lg-5 col-xl-4">
                                <div class="fw  text-center s-camdetail-copy">
                                    <h3 class="txt-light txt-w-xl">ร่วมติดตามผล แหล่งท่องเที่ยว<span style="display:inline-block;">Unseen New Chapters</span>ปักหมุดมุมใหม่ เปิดไทยมุมต่าง</h3>
                                    <p class="txt-light">ที่จะประกาศพร้อมกันทั่วประเทศ<br class="d-md-none d-sm-block">วันอังคารที่ 27 มิถุนายน 2566</p>
                                    <div class="box-ctr-btn-group d-lg-block d-none ">
                                        <button v-if="this.userAssets == null || this.userAssets == ''" @click="openRegister()" class="btn btn-ctr-light close-btn-pre btn-lg rounded-pill px-4 my-3">ร่วมโหวตลุ้นรางวัล</button>
                                        <router-link v-else class="btn btn-ctr-light  btn-lg rounded-pill px-4 my-3 close-btn-pre" to="/attractions">ร่วมโหวตลุ้นรางวัล</router-link>
                                        <!-- <a href="attractions" class="btn btn-ctr-light  btn-lg rounded-pill px-4 my-3">ร่วมโหวตลุ้นรางวัล</a> -->
                                        <!-- <p class="txt-light">ร่วมสนุกได้ตั้งแต่ 22 พ.ค. 66 เวลา 10.00 น. - <span style="display: inline-block;">18 มิ.ย.66 เวลา 24.00 น.</span></p> -->
                                        <!-- <router-link to="rules-reward" class="mt-4 btn-ctr-primary btn-txt btn-icon text-decoration-underline"><span ><span class="me-2">กติกาการร่วมสนุก</span><img class="svg"  src="@/assets/img/skin/icon-arrow.svg" alt=""></span></router-link> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-6 col-lg-3 col-xl-4">
                                <div class="fw text-center s-camdetail-gift s-camdetail-gift-1">
                                    <img src="@/assets/img/home/reward-1.png" alt="">
                                    <div class="fw">
                                        <h5 class="txt-light d-flex align-items-center justify-content-center text-xl-center text-start"><span><img class="me-md-3 me-1" src="@/assets/img/home/rw-txt-1.png" alt=""></span> ของรางวัล<br class="d-md-none">ประจำสัปดาห์</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-6 col-lg-4 col-xl-4">
                                <div class="fw text-center s-camdetail-gift s-camdetail-gift-2">
                                    <img src="@/assets/img/home/reward-2.png" alt="">
                                    <div class="fw">
                                        <h5 class="txt-light d-flex align-items-center justify-content-center text-xl-center text-start"><span><img class="me-md-3 me-1" src="@/assets/img/home/rw-txt-2.png" alt=""></span>ของรางวัล<br class="d-md-none">กองเชียร์</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-lg-none">
                                <div class="box-ctr-btn-group fw text-center">
                                    <button v-if="this.userAssets == null || this.userAssets == ''" @click="openRegister()" class="btn btn-ctr-light close-btn-pre btn-lg rounded-pill px-4 my-3">ร่วมโหวตลุ้นรางวัล</button>
                                    <router-link v-else class="btn btn-ctr-light  btn-lg rounded-pill px-4 my-3 close-btn-pre" to="/attractions">ร่วมโหวตลุ้นรางวัล</router-link>
                                    <!-- <a href="attractions" class="btn btn-ctr-light  btn-lg rounded-pill px-4 my-3">ร่วมโหวตลุ้นรางวัล</a> -->
                                    <!-- <p class="txt-light">ร่วมสนุกได้ตั้งแต่ 22 พ.ค. 66 เวลา 10.00 น. - <span style="display: inline-block;">18 มิ.ย.66 เวลา 24.00 น.</span></p> -->
                                    <p class="txt-light">ที่จะประกาศพร้อมกันทั่วประเทศ<br class="d-md-none d-sm-block">วันอังคารที่ 27 มิถุนายน 2566</p>
                                    <!-- <router-link to="rules-reward" class="mt-4 btn-ctr-primary btn-txt btn-icon txt-w-l underline"><span ><span class="me-2">กติกาการร่วมสนุก</span><img class="svg"  src="@/assets/img/skin/icon-arrow.svg" alt=""></span></router-link> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="st sec-home-top-rank pd">
            <div class="container container-1360">
                <div class="position-relative">
                    <div class="box-title text-center mb-4">
                        <h2 class="font-display"><span class="gd-hl">แหล่งท่องเที่ยวสุด Unseen</span></h2>
                        <h4><b>10 อันดับแรกที่ได้รับโหวตสูงสุด ประจําสัปดาห์</b></h4>
                    </div>
                    <div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-5 g-4 scroll-swap">
                        <template v-for="(zone , index) in travelList.posts " :key="zone.id">
                        <div class="col scroll-swap-item" v-if="index <= 9"  >
                            <div class="card card-vote " :class="[itemVote(zone.region_en,zone.id,zone.region_en), 'location_'+zone.id]">
                                <div class="card-img pop-content">
                                    <div class="rating txt-w-lg">{{ index+1 }}</div>
                                    <img  
                                    @click="handlePopContentClick(zone.id,zone.location_name,zone.location,zone.province_th,zone.region_th,zone.detail,replaceExtension(zone.image_cover),zone.images,zone.vote,zone.region_en,zone.tel,zone.location_link)"
                                    :src="require(`../assets/img/77-attractions/${replaceExtension(zone.image_cover)}`)" alt="" class="fw">
                                    <a v-if="winW <= 1199" href="javascript:void(0)" @click="handleVote(zone.id,replaceExtension(zone.image_cover),zone.location_name, zone.province_th,zone.region_th,zone.region_en)" class="card-like card-action-default btn-on-card">
                                        <span><img src="../assets/img/skin/icon-heart-vote.svg" class="svg"
                                            alt=""><span class="vote-num">{{ zone.vote >= 1000 ? (Math.round(zone.vote)).toLocaleString() : Math.round(zone.vote) }}</span><span
                                            class="vote-txt">VOTE</span></span>
                                    </a>
                                </div>
                                <div class="card-body px-0 pb-0">
                                    <div class="card-cont pop-content" @click="handlePopContentClick(zone.id,zone.location_name,zone.location,zone.province_th,zone.region_th,zone.detail,replaceExtension(zone.image_cover),zone.images,zone.vote,zone.region_en,zone.tel,zone.location_link)">
                                        <h6 ref="cardContentLocation"><b v-html="zone.location_name "></b></h6>
                                        <p class="gray-dark"><small>จ.{{ zone.province_th  }} {{ zone.region_th }}</small></p>
                                    </div>
                                    <a v-if="winW > 1199" href="javascript:void(0)" @click="handleVote(zone.id,replaceExtension(zone.image_cover),zone.location_name, zone.province_th,zone.region_th,zone.region_en), handleClick('Vote-Thumb',zone.location_name)" class="card-like card-action-default">
                                        <span><img src="../assets/img/skin/icon-heart-vote.svg" class="svg"
                                            alt=""><span class="vote-num">{{ zone.vote >= 1000 ? (Math.round(zone.vote)).toLocaleString() : Math.round(zone.vote) }}</span><span
                                            class="vote-txt">VOTE</span></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        </template>
                    </div>
                    <div class="fw text-center pt-3 pt-md-5">
                        <button v-if="this.userAssets == null || this.userAssets == ''" @click="openRegister()" class="btn btn-ctr-primary btn-lg rounded-pill px-4">ดูแหล่งท่องเที่ยว UNSEEN 77 จังหวัด</button>
                        <router-link v-else class="btn btn-ctr-primary btn-lg rounded-pill px-4" to="/attractions">ดูแหล่งท่องเที่ยว UNSEEN 77 จังหวัด</router-link>
                    </div>
                </div>
            </div>
        </div>


        <!-- <div class="group-bg-foot fw" v-sm-scene="{ triggerElement: '.group-bg-foot', duration: 200 }"> -->
            <div class="group-bg-foot fw">
            <div class="st sec-home-reward pd">
                <div class="bg-home-reward"></div>
                <div class="container container-1360 mb-5">
                    <div class="position-relative">
                        <div class="ele ele-left-white-balloon"><img src="@/assets/img/element/white-balloon-l.png" alt=""></div>
                        <div class="ele ele-left-gold-balloon"><img src="@/assets/img/element/gold-balloon-r.png" alt=""></div>
                        <div class="ele ele-left-gift"><img src="@/assets/img/element/gift-1.png" alt=""></div>
                        <div class="ele ele-left-cloud"><img src="@/assets/img/element/cloud-1.png" alt=""></div>
                        <div class="ele ele-right-white-balloon"><img src="@/assets/img/element/white-balloon-r.png" alt=""></div>
                        <div class="ele ele-right-gold-balloon"><img src="@/assets/img/element/gold-balloon-l.png" alt=""></div>
                        <div class="ele ele-right-gift"><img src="@/assets/img/element/gift-1.png" alt=""></div>
                        <div class="ele ele-right-cloud"><img src="@/assets/img/element/cloud-1.png" alt=""></div>
                        <div class="box-title text-center mb-4">
                            <h2 class="font-display"><span class="txt-light">เพียงคุณร่วมโหวต</span></h2>
                            <h4><b>แหล่งท่องเที่ยวสุด Unseen ลุ้นรับของรางวัลถึง 2 ต่อ</b></h4>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 s-hreward s-hreward-1">
                                <div class="fw text-center">
                                    <img class="s-hreward-title" src="@/assets/img/home/topic-gift-1.png" alt="">
                                    <img class="w-100" src="@/assets/img/home/gift-home-1.png" alt="">
                                    <div class="s-hreward-text primary">สำหรับผู้ที่ร่วมโหวต<br><b class="primary">ลุ้นรับของรางวัลพิเศษในแต่ละสัปดาห์</b></div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 s-hreward s-hreward-2">
                                <img src="@/assets/img/home/arrow-title-award.png" class="ele-arrow-title" alt="">
                                <div class="fw text-center">
                                    <img class="s-hreward-title"  src="@/assets/img/home/topic-gift-2.png" alt="">
                                    <img class="w-100" src="@/assets/img/home/gift-home-2.png" alt="">
                                    <div class="txt-primary primary">สำหรับผู้ที่ร่วมกิจกรรม <br><b class="primary">#UNSEENNEWCHAPTERS</b></div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>

                <div v-if="hide" class="st sec-howto-play  mb-4">
                    <div class="container container-1360">
                        <div class="position-relative">
                            <div class="box-title text-center mb-4">
                                <h2 class="font-display"><span class="gd-hl">ขั้นตอนการลงทะเบียน</span></h2>
                                <h4><b>โหวตแหล่งท่องเที่ยว “Unseen New Chapters”</b></h4>
                            </div>

                            <div class="fw">
                                <img src="@/assets/img/home/howto-play.jpg" alt="" class="fw rounded-4">
                            </div>
                        
                        </div>
                    </div>
                </div>
                <div class="fw text-center pt-lg-2">
                            <router-link to="rules-reward" class="btn btn-ctr-primary  btn-lg rounded-pill px-4 my-3">ดูรายละเอียดกติกาและของรางวัล</router-link>
                        </div>
            </div>
            <div class="st sec-home-promotion">
                <div class="container container-1360">
                    <div class="position-relative">
                        <div class="box-title text-center mb-4">
                            <h2 class="font-display"><span class="gd-hl">Unseen Deals</span></h2>
                            <h4><b>โปรโมชั่นสุดพิเศษ</b></h4>
                        </div>

                        <div class="promotion-swiper fw">
                            <div class="box-promotion-slide">
                                <div class="swiper promotion_slide">
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide" v-for="item in promotions" :key="item.id">
                                            <div class="card-promotion"  @click="inItPromotion(item.name,item.description,item.image ,item.link_out)">
                                                <div class="card-promotion-image">
                                                    <img :src="require(`../assets/img/promotion/${item.image}`)" alt="" class="fw">
                                                </div>
                                                <div class="card-promotion-text text-start">
                                                    <div class="card-pro-title" v-html="item.name"></div>
                                                    <a href="javascript:void(0)" class="btn-txt-dark btn-icon d-inline-block"><span ><span class="">ดูรายละเอียด</span><img class="svg" src="@/assets/img/skin/icon-arrow-right-primary.svg" alt=""></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-pagination"></div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="fw text-center mt-4">
                            <router-link to="/promotion" class="btn btn-ctr-primary  btn-lg rounded-pill">ดูโปรโมชั่นเพิ่มเติม</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <popUpVoteList @connect-api="handleVoteError" :style="voteError == true ? 'display: none;' : ''" @vote-success="handleVoteSuccess" @session-timeout="handleSession" v-if="voteShow == true" @after-vote="afterVote" :locationTicker="locationTicker" :userId="profileData.id" v-on:close-popup="[voteShow = false , show = false]" v-on:close-popupVote="[voteShow = false , show = false]"  @button-clicked="autoTab"></popUpVoteList>

    <loadPageComponent v-if="isloade" class="loadpage-com" :class="isloade == true ? isActive : isActive = '' "></loadPageComponent>
    <loadApiComponent v-if="isloadeApi" class="loadpage-com loadApi " :class="isloadeApi == true ? isActive : isActive = '' "></loadApiComponent>
     
    <popupDetail  v-if="show" @vote-clicked="handleVoteClicked"  v-on:close-popup="show = false,this.$root.metaDefault()" :popupContArray="popupContArray"></popupDetail>
    <popupError v-if="voteError"></popupError>

    <popupDup v-if="popupVoteDup"></popupDup>
    <popupSession v-if="popSession"></popupSession>

    <popupPromotion v-if="isPromotion" v-on:close-popup="isPromotion = false, startAutoplay();" :promotionArray="promotionArray"></popupPromotion>
</template>


<script>
/* eslint-disable */



import { setFunction } from '@/global'
import { bannerSlide } from '@/global'
import popupDetail from '@/components/popup/popupDetail.vue';
import popUpVoteList from '@/components/popup/popUpVoteList.vue';
import loadPageComponent from '@/components/loadPageComponent.vue';
import loadApiComponent from '@/components/loadPageComponent.vue';
import popupDup from '@/components/popup/popupVoteDup.vue';
import popupError from '@/components/popup/popupError.vue';
import popupPromotion from '@/components/popup/popupPromotion.vue';
import popupSession  from '@/components/popup/popupCheclSession.vue';
// STORE
import { mapState } from 'vuex';
import axios from 'axios';
// SWIPER
import Swiper, { Autoplay, Navigation, Pagination, Controller, EffectFade, } from 'swiper';
Swiper.use([Autoplay, Navigation, Pagination, Controller, EffectFade]);
import "swiper/swiper-bundle.css";
import 'swiper/css/pagination';
import Cookies from 'js-cookie';
import { TweenMax, Power1, TimelineMax } from 'gsap';

export default {
    name: 'HomeView',
    components: { popupDetail, popUpVoteList,loadPageComponent,loadApiComponent,popupError,popupDup,popupPromotion ,popupSession},
    data() {
        return {
            popSession: false,
            voteError: false,
            isActive:'active',
            isloade: true,
            isloadeApi:false,
            intervalLocation: null,
            swiper: null,
            popupContArray: [],
            show: false,
            checkId: false,
            voteShow: false,
            dataEncode: null,
            testArray: [],
            hidePreview: true,
            hide: false,
            showLoading: false,
            dataCheck: '',
            locationTicker: [],
            location_ID: '',
            userAssets:  sessionStorage.getItem('USER_INFO'),
            login_Status: Cookies.get('login_status'),
            openPopAuthClick: false,
            profileData: '',
            historyData: null,
            checkTabVote: false,
            tabRegion: 'north',
            votesData:[],
            current_week: '',
            winW :window.innerWidth,
            popupVoteDup: false,
            promotions: [],
            isPromotion:false,
            promotionArray:[],
            isPlay:false,
            promotionSlideInit: null
            
            
        }
    },
    // userAssets: sessionStorage.getItem('USER_INFO'),
    computed: mapState(['travelList']),
    mounted() {
        document.title = 'Unseen New Chapters ปักหมุดใหม่ เปิดไทยมุมต่าง';
        bannerSlide();
        window.scrollTo(0, 0);

        this.winW = window.innerWidth;
        window.addEventListener('resize', () => {
            this.winW = window.innerWidth;
        });

        if (this.login_Status == 1 ) {
            if (this.userAssets != null) {
                let decodedDataString = atob(this.userAssets);
                let decodedArray = JSON.parse(decodedDataString);

                this.profileData = decodedArray.profile;
                this.historyData = decodedArray.history;

                // // // console.log('decodedArray',decodedArray);

                this.loginStatus = true;
                this.current_week = 'week_' + decodedArray.history.current_week;
                window.addEventListener("storage", (event) => {
                    if (sessionStorage.getItem('USER_INFO') !== null|| sessionStorage.getItem('USER_INFO') !== undefined) {
                        let decodedDataString = atob(sessionStorage.getItem('USER_INFO'));
                        let decodedArray = JSON.parse(decodedDataString);
                        this.profileData = decodedArray.profile;
                        this.historyData = decodedArray.history;
                        this.current_week = 'week_' + decodedArray.history.current_week;
                        // // // console.log('sessionStorage change');
                    }
                });
                 window.addEventListener("change", (event)  => {
                    if (Cookies.get('login_status') == undefined) {
                        sessionStorage.removeItem('USER_INFO');
                        // // // console.log('cookieStore change removeItem');
                    }else{
                        let decodedDataString = atob(sessionStorage.getItem('USER_INFO'));
                        let decodedArray = JSON.parse(decodedDataString);
                        this.profileData = decodedArray.profile;
                        this.historyData = decodedArray.history;
                        this.current_week = 'week_' + decodedArray.history.current_week;
                        // // // console.log('sessionStorage change');
                    }
                });

            }
        }else{
            sessionStorage.removeItem('USER_INFO');
        }
       

        if (this.$route.query.slug != undefined) {
            this.urlInitPopup(this.$route.query.slug);
        }

        this.home_animate();
        this.promotionSlide();

        // window.addEventListener('load', () => {
        //     this.fetchPosts();
            
        // });
        this.getPromotions();
        // this.fetchPosts();
        this.handleWindowFocus();
        // window.addEventListener('focus', this.handleWindowFocus);
        // window.addEventListener('blur', this.handleWindowBlur);


        

        // var isloadeLoop =  setInterval(() => {
        //     if(this.travelList.posts.length > 0 || this.travelList.posts != ''){
        //         this.isloade = false
        //         clearInterval(isloadeLoop);
        //     }else{
        //         this.isloade = true;
        //     }
        // }, 1000);


        setTimeout(() => {
            var isloadeLoop =  setInterval(() => {
                // // // console.log(this.travelList.zones);
               
               try {
                    if(this.travelList.posts.length > 0 ||this.travelList.posts != ''){
                        if (document.querySelector('.loadpage-com') != null) {
                            document.querySelector('.loadpage-com').classList.remove('active')
                            setTimeout(() => {
                                this.isloade = false;
                                clearInterval(isloadeLoop);
                                setFunction();
                            }, 200);
                        }
                    }else{
                        this.isloade = true;
                    }
               } catch (error) {
                if (document.querySelector('.loadpage-com') != null) {
                    document.querySelector('.loadpage-com').classList.remove('active')
                    setTimeout(() => {
                        this.isloade = false;
                        clearInterval(isloadeLoop);
                        setFunction();
                    }, 200);
                }
               }
                
            }, 1000);
        }, 500);


       setTimeout(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth' // Add smooth scrolling animation
        });
        
       }, 500);


       const observer = new MutationObserver(this.handleBodyAttributeChange);
        observer.observe(document.querySelector('body'), { attributes: true });

        // Interval Comment   
        // window.addEventListener('focus', this.handleWindowFocus);
        // window.addEventListener('blur', this.handleWindowBlur);
        
    },
    created(){
        const observer = new MutationObserver(this.handleBodyAttributeChange);
        observer.observe(document.querySelector('body'), { attributes: true });
        // var is_pre = document.querySelector('body').getAttribute('data-pre-campaign');
        // var is_end = document.querySelector('body').getAttribute('data-end-campaign');
        // if (is_pre == null || is_end == null) {
        //     window.addEventListener('focus', this.handleWindowFocus);
        //     window.addEventListener('blur', this.handleWindowBlur);
    
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth' // Add smooth scrolling animation
        });
    },
    created(){
    // }
    //     if (this.isPlay == true) {
    //     // console.log('isPlay',this.isPlay);
       
    //   }else{
    //     // console.log('isPlay 2',this.isPlay);
    //     window.removeEventListener('focus', this.handleWindowFocus);
    //     window.removeEventListener('blur', this.handleWindowBlur);
    //     clearInterval(this.intervalLocation);
    //   }
    },
    beforeUnmount() {
        // Clear interval and remove event listeners before component is destroyed
        clearInterval(this.intervalLocation);
        window.removeEventListener('focus', this.handleWindowFocus);
        window.removeEventListener('blur', this.handleWindowBlur);
         const observer = new MutationObserver(this.handleBodyAttributeChange);
        observer.disconnect();

    },
    methods: {

        replaceExtension(imageUrl) {
            return imageUrl.replace(/\.jpg$/, '.webp');
        },
        handleBodyAttributeChange(mutationsList) {
            // const bodyElement = document.querySelector('body');
            // const isPreCampaign = bodyElement.getAttribute('data-pre-campaign');
            // const isEndCampaign = bodyElement.getAttribute('data-end-campaign');
            
           
            // if (isPreCampaign === null && isEndCampaign === null) {
              
            //     this.isPlay  = true;
            //     // console.log('if',this.isPlay );
            // } else {
               
            //     this.isPlay  = false;
            //     // console.log('else',this.isPlay );
            // }
        },
        
        handleClick(name,value) {
            if (Cookies.get('pdpa_consent_unseen') == 'active') {
                this.clickTracking(name,value);
            }
        },
        
        openRegister(){
            if (this.userAssets == null || this.userAssets == '') {
                document.getElementById('registerbtn').click()
            }
        },
        handleVoteError(Error){
            if (Error) {
                this.popupVoteDup =  Error;
            }
            
        },
        handleVoteSuccess(success) {
            if (success) {
                this.isloadeApi = success;
              
            } else {
                this.isloadeApi = success
            }
        },

        handleSession(success){
            if (success) {
                this.popSession = success;
            } else {
                this.popSession = success
            }
        },
        handleVoteStart(success){
            if (success) {
                this.isloadeApi = success
            } else {
                this.isloadeApi = success
                
            }
        },
        noDataFound(){
            let url = new URL(window.location.href);
            url.searchParams.delete('slug');
            window.history.replaceState(null, null, url.href);
        },
        // createScene() {
        //     new ScrollMagic.Scene({
        //         triggerElement: '.sec-home-top-rank',
        //         duration: 500
        //     })
        //     .setTween(TweenMax.to('.sec-home-top-rank .box-title', 1, {opacity: 0, y: -50}))
        //     .addTo(this.controller);
        // },
        fetchPosts() {
            // console.log('fetchPosts');
            this.$store.dispatch('fetchPosts');
            // this.regionClose()
            // this.afterVote();
            this.regionClose();
            
            setTimeout(() => {
                setFunction();
            }, 100);
        },
        handleWindowFocus() {
            // console.log('start');
            this.fetchPosts();
            // Start interval when window is in focus
            // this.intervalLocation = setInterval(this.fetchPosts, 20000);
        
        },
        handleWindowBlur() {
            // console.log('do is Blur');
            // Clear interval when window is out of focus
            clearInterval(this.intervalLocation);
        },
        prev() {
            this.swiper.slidePrev();
        },
        next() {
            this.swiper.slideNext();
        },
        // bannerSlide(){
        //     this.bannerSlide = new Swiper('.box-banner-slide .banner_slide', {
		// 		spaceBetween: 10,
		// 		slidesPerView: 1,
		// 		pagination: {
        //             el: '.box-banner-slide .swiper-pagination',
        //             type: 'bullets',
        //             clickable : true
        //         },
        //         autoplay: {
        //             delay: 5000,
        //         },
		// 	});
        // },
        async getPromotions() {
            this.isloade = true
                try {
                    const response = await axios.get(`https://uat.unseennewchapters.com/nx/API/get_promotions`);
                    if (response.data.rs == true) {
                        this.promotions = response.data.data.slice(0,6);

                    }
                    
                } catch (error) {

                    this.isloade = true
                }
        },

        inItPromotion(name,description,image ,link_out){
            this.stopAutoplay();
            this.isPromotion = true;
            this.promotionArray = [{
                name: name,
                description:description,
                image:image,
                link_out:link_out,
            }]
        },
        promotionSlide(){
            this.promotionSlideInit = new Swiper('.box-promotion-slide .promotion_slide', {
				spaceBetween: 10,
				slidesPerView: 1.4,
				pagination: {
                    el: '.box-promotion-slide .swiper-pagination',
                    type: 'bullets',
                    clickable : true
                },
                speed: 1000,
                autoplay: {
                    delay: 1000,
                    disableOnInteraction: true,
                },
                breakpoints: {
                    1119: {
                        slidesPerView: 3.5,
                        simulateTouch: true,
                    },
                    767: {
                        slidesPerView: 2.5,
                        simulateTouch: true,
                    },
                },
                
			});

        },
        stopAutoplay() {
            this.promotionSlideInit.autoplay.stop();
        },
        startAutoplay() {
            this.promotionSlideInit.autoplay.start();
        },
        home_animate() {
            TweenMax.from('.navbar-brand',1, { opacity: 0, y:-100,},.2);
            TweenMax.from('.banner-cont .cont-l img',1, { opacity: 0, y:200,},.2);
            TweenMax.from('.banner-cont .cont-l .banner-t-1',1, { opacity: 0, y:200,},.4);
            TweenMax.from('.banner-cont .cont-l .banner-t-2',1, { opacity: 0, y:200,},.6);
            TweenMax.from('.banner-cont .cont-r',1, { opacity: 0, x:200,},.6);

            TweenMax.from('.sec-home-campaign',1, { opacity: 0, y:200,},1);
            TweenMax.from('.s-camdetail-copy',1, { opacity: 0, y:200,},1.2);
            TweenMax.from('.s-camdetail-gift-1',1, { opacity: 0, y:200,},1.4);
            TweenMax.fromTo('.s-camdetail-gift-1 > img', 3, {y: 0}, {y: -20, ease: Power1.easeInOut, repeat: -1, yoyo: true,delay:0.8});
            TweenMax.from('.s-camdetail-gift-2',1, { opacity: 0, y:200,},1.6);
            TweenMax.fromTo('.s-camdetail-gift-2 > img', 3, {y: 0}, {y: -20, ease: Power1.easeInOut, repeat: -1, yoyo: true});


            TweenMax.fromTo('.s-hreward-1 img.w-100', 3, {y: 0}, {y: -20, ease: Power1.easeInOut, repeat: -1, yoyo: true,delay:0.8});
            TweenMax.fromTo('.s-hreward-2 img.w-100', 3, {y: 0}, {y: -20, ease: Power1.easeInOut, repeat: -1, yoyo: true});

        },

        handleVoteClicked(data) {
            const arrayClick = data;
            const isVotesData = [];
            isVotesData.push(arrayClick);
            this.handleVote(isVotesData[0].id,isVotesData[0].image_cover,isVotesData[0].location_name,isVotesData[0].province_th,isVotesData[0].region_th,isVotesData[0].region_en)
        },
        async urlInitPopup(slug) {
            const popUpIsslug = slug;
            if (popUpIsslug != undefined || popUpIsslug != '') {
                try {
                    const response = await axios.get(`https://uat.unseennewchapters.com/nx/API/get_locations/${popUpIsslug}`);
                    if (response.data.rs == true) {
                        this.handlePopContentClick(response.data.data.id,response.data.data.location_name,response.data.data.location,response.data.data.province_th,response.data.data.region_th,response.data.data.detail,response.data.data.image_cover,response.data.data.images,response.data.data.vote,response.data.data.region_en,response.data.data.tel,response.data.data.location_link)
                    }else{
                        this.noDataFound();
                    }
                    
                    
                } catch (error) {
                    this.noDataFound();
                }
            }else{
                this.noDataFound();
            }
                
        },
        handlePopContentClick(id,location_name,location,province_th,region_th,detail,image_cover,images,vote,region_en,tel,location_link) {
            this.show = true;
            this.popupContArray=[{
                id:id ,location_name:location_name,location:location,province_th:province_th,region_th:region_th,detail:detail,image_cover:image_cover,images:images,vote:vote,region_en:region_en,tel:tel,location_link:location_link
            }]
            window.history.pushState(null, null, `?slug=${id}`);

        },
        afterVote(){
            // // console.log(this.$root);
            this.$store.dispatch('fetchPosts');
            
            setTimeout(() => {
                this.updateData();
                setFunction();
            }, 100);
        },

        // ...mapActions('voteStoreData', ['updateData']),
        handleVote(locationId,imageCover,location_name,location_province_th,location_region_th,location_region_en) {
            this.userAssets = sessionStorage.getItem('USER_INFO');
            if (this.userAssets == null || this.userAssets == '') {
                try {
                    document.getElementById('registerbtn').click()

                } catch (error) {
                    window.location.reload();
                }


            } else {
                this.voteShow = true;
                this.location_ID = locationId;
                this.locationTicker = [{
                    location_ID: locationId,
                    imageCover:imageCover,
                    location_name:location_name,
                    location_province_th:location_province_th,
                    location_region_th:location_region_th,
                    location_region_en:location_region_en,
                }]
            }



        },
        isVote(locationEN) {
                const tabRegions = this.historyData;
                if (tabRegions != null) {
                    return tabRegions[this.current_week].hasOwnProperty(locationEN) ? true : false;
                }
                
        },

        updateData() {
            if (sessionStorage.getItem('USER_INFO')) {
                const decodedDataString = atob(sessionStorage.getItem('USER_INFO'));
                const decodedArray = JSON.parse(decodedDataString);
                this.profileData = decodedArray.profile;
                this.historyData = decodedArray.history;
                this.current_week = 'week_' + decodedArray.history.current_week;

                const arrayWeek = this.historyData[this.current_week];
                for (const key in arrayWeek) {
                    if (arrayWeek.hasOwnProperty(key)) {
                        const value = arrayWeek[key];
                        this.itemVote(value[0].region_en,value[0].id,value[0].region_en)
                    }
                }
            }
        },
        regionClose() {
            if (sessionStorage.getItem('USER_INFO')) {
                const decodedDataString = atob(sessionStorage.getItem('USER_INFO'));
                const decodedArray = JSON.parse(decodedDataString);
                this.profileData = decodedArray.profile;
                this.historyData = decodedArray.history;
                this.current_week = 'week_' + decodedArray.history.current_week;
                // console.log('regionClose');
                const arrayWeek = this.historyData[this.current_week];
                for (const key in arrayWeek) {
                    if (arrayWeek.hasOwnProperty(key)) {
                        const value = arrayWeek[key];
                        const region_en = value[0].region_en;
                        const is_id = value[0].id;
                        const itemVote = this.historyData;
            
                        if (itemVote != null) {
                        
                            if ( itemVote[this.current_week].hasOwnProperty(region_en)) {
                            
                                if (itemVote[this.current_week][region_en][0].region_en == region_en) {
                                    const elements = document.querySelectorAll('.card-vote');

                                    if ( itemVote[this.current_week][region_en][0].location_id == is_id ) {
                                            elements.forEach(element => {
                                                const locationId = itemVote[this.current_week][region_en][0].location_id;
                                                const className = `location_${locationId}`;

                                                if (element.classList.contains(className)) {
                                                    element.classList.add('itemIsVote');
                                                }
                                            });
                                    }else{
                                        elements.forEach(element => {
                                                const locationId = itemVote[this.current_week][region_en][0].location_id;
                                                const className = `location_${locationId}`;

                                                if (element.classList.contains(className)) {
                                                    element.classList.add('closeVote');
                                                }
                                            });
                                    }
                                }
                                
                            }
                        }
                    }
                }
            }
        },
        itemVote(index,locationId,region_en){
            const itemVote = this.historyData;
            
            if (itemVote != null) {
              
                if ( itemVote[this.current_week].hasOwnProperty(index)) {
                  
                    if (itemVote[this.current_week][index][0].region_en == region_en) {
                        if ( itemVote[this.current_week][index][0].location_id == locationId ) {
                            return 'itemIsVote'
                        }else{
                            return 'closeVote' 
                        }
                    }
                    
                }
            }
          
        },
    }
}
</script>
<style>

.sec-home-main { position: relative;}
.ele-main-kv-cloud { position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); width: 1920px; z-index: 2;}
.ele-main-kv-pin {position: absolute;bottom: 320px;right: 0;width: 320px;}
.banner-cont .cont-l { width: 40%;}
.banner-cont .cont-l .banner-t-1{ font-size: 2.5em;margin-top: 10px;}
.banner-cont .cont-l .banner-t-2 { font-size: 2em; color: #fff;}
.banner-cont .cont-r { width: 60%;}
.box-banner-slide { position: relative;}
.box-banner-slide .swiper-pagination{ bottom: -40px!important;}
.banner-cont{display: flex;align-items: center;}

.cont-l{width: 50%;}
.cont-r{width: 50%;}
.cont-r {background-color: #fff;border-radius: 20px;padding: 10px;}
.cont-r .swiper{border-radius: 20px;overflow: hidden;}
.cont-r .swiper .swiper-slide .box-connt-baner{width: 100%;float: left; border-radius: 20px;overflow: hidden;}
.cont-r .swiper img{width: 100%;float: left;}

.sec-home-campaign{border-radius: 20px; padding:2em 2em 10em; margin-top: 7em; position: relative;}
.s-camdetail-gift { margin-top: -8em;}
.s-camdetail-gift-1 > img { height: 350px;}
.s-camdetail-gift-2 > img { height: 350px;}
.s-camdetail-gift .txt-light img { width: 75px;}
.sec-home-main{background: url(../assets/img/home/bg-banner-home.jpg)  center top no-repeat; background-size: cover;}



.group-bg-foot{background: url(../assets/img/home/bg-kv-bottom.jpg)  center bottom no-repeat;}
.sec-home-reward { position: relative;}
.bg-home-reward { width: 100%; height: 100%; position: absolute; top: 0; left: 0;background: rgb(234,78,164);
background: linear-gradient(0deg,rgb(255 239 250 / 0%) 0%,#FFEFEB 37.49%,#ec268f 184.23%);pointer-events: none;}
.sec-home-promotion { padding-bottom: 10em;}
.box-promotion-slide { position: relative; }
.box-promotion-slide .swiper-pagination{ bottom: -10px!important; position: relative;}
.box-promotion-slide .swiper { overflow: visible;}
.card-promotion { background-color: #fff; padding: 10px; border-radius:10px;cursor: pointer;width: 100%;float: left;}
.card-promotion:hover .card-promotion-image img{transform: scale(1.1);}
.card-promotion-image img{transition: all ease-in-out .4s;}
.card-promotion-image{width: 100%;float: left;overflow: hidden;border-radius: 10px;}
.card-promotion-text{width: 100%;float: left;padding-top: 10px;}
.card-promotion-text .btn-icon {color:var(--color-primary);font-weight: 400;}
.card-promotion-text .btn-icon svg{fill:var(--color-primary);}
.card-pro-title{font-weight: 500;}
.s-hreward { font-size: 1.5em;}
.ele-left-white-balloon {position: absolute;left: 50%;margin-left: -65%;top: 50%;transform: translateY(40%); width:10%}
.ele-left-gold-balloon { position: absolute;left: 50%;margin-left: -48%;top: 50%;transform: translateY(-110%);width: 6%;}
.ele-left-gift {position: absolute;left: 50%;margin-left: -56%;top: 50%;transform: translateY(-21%) rotate(5deg);width: 10%;}
.ele-left-cloud {position: absolute;top: 50%; left: 50%; transform: translateY(52%);margin-left: -89%;width: 49%;}
.ele-left-cloud img { width: 100%;}

.ele-right-white-balloon {position: absolute;top: 50%; right: 50%;transform: translateY(-30%);margin-right: -60%; width:18%}
.ele-right-gold-balloon {position: absolute;top: 50%; right: 50%;transform: translateY(70%);margin-right: -68%;width: 10%;}
.ele-right-gift {position: absolute;top: 50%; right: 50%;transform: translateY(99%) rotate(-45deg);margin-right: -58%;width: 10%;}
.ele-right-cloud {position: absolute;top: 50%; right: 50%; transform: translateY(52%);margin-right: -89%;width: 49%;}
.ele-right-cloud img { width: 100%;}




.popup {position: fixed;z-index: 9999;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5);display: flex;justify-content: center;align-items: center;}

.popup-content {background-color: #fff;padding: 20px;border-radius: 4px;box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);max-width: 500px;width: 100%;}

.row {display: flex;flex-wrap: wrap;}

.col-left {flex: 0 0 150px;margin-right: 20px;}

.col-right {flex: 1 !important ;overflow: auto;/* Add scrollbar to right column */}

.col-left img {width: 100%;height: auto;}

.popup-content h2 {margin-top: 0;}

.popup-content p {margin-bottom: 20px;}

.fade-enter-active,
.fade-leave-active {transition: opacity 0.5s;}

.fade-enter,
.fade-leave-to {opacity: 0;}

.popup-content.show .fade-enter {opacity: 1;}
.swiper-container {width: 100%;height: 100%;}
.swiper-slide img {width: 100%;height: 100%;object-fit: cover;}

.closeVote .card-like,.itemIsVote .card-like{pointer-events: none;}
.closeVote .card-like svg{fill: #B1B1B1;}
.closeVote .card-like svg path{fill: #B1B1B1 !important;}
.closeVote .card-like {color: #B1B1B1;background-color: #E2E2E2;border: 2px solid #E2E2E2;}

.itemIsVote .card-like svg{fill: #fff;}
.itemIsVote  .card-like svg path{fill: #fff !important;}
.itemIsVote .card-like {color: #fff;background-color: var(--color-primary);    border: 2px solid var(--color-primary);}


/* .s-hreward-2 .s-hreward-title::before{content: "";position: absolute;top: 0;background-image: url();} */
.s-hreward-2{position: relative;}
img.ele-arrow-title {
    position: absolute;
    left: -24%;
    top: 5%;
    transform: translate(50%,50%);
    width: 24%;pointer-events: none;
}

.card-promotion .btn-txt-dark:hover { background-color:transparent; color:var(--color-primary);}
.card-promotion .btn-txt-dark:hover svg { fill:var(--color-primary); }

.banner-cont .logo-kv{height: 260px;}
@media (max-width: 1699.98px){
    .ele-main-kv-pin {position: absolute;bottom: 320px;right: auto;width: 320px;left: calc(50% + 320px);}
}
@media (max-width: 1359.98px){
    span.box-welcome-txt-name {max-width: 130px;width: 100%;overflow: hidden;text-overflow: ellipsis;-webkit-box-orient: vertical;display: -webkit-box;-webkit-line-clamp: 1;}
    img.ele-arrow-title{top: 7%;}
    .banner-cont .cont-l .banner-t-2{font-size:1.8em;}
}


@media (max-width: 1199.98px){
    span.box-welcome-txt-name {width: 100%;overflow: hidden;text-overflow: ellipsis;-webkit-box-orient: vertical;display: -webkit-box;-webkit-line-clamp: 2;}
    .banner-cont .logo-kv{height: 155px;}
    .s-camdetail-gift-1 > img { height: 230px;}
    .s-camdetail-gift-2 > img { height: 230px;}
    .sec-home-campaign{margin-top:5em;}
    .s-camdetail-gift{margin-top:-5em;}
    .cont-r{border-radius: 23px; padding: 3px;}

    img.ele-arrow-title{top: 9%;}

    /*.bg-home-reward{    background: linear-gradient(180deg, rgba(234,78,164,1) 0%, rgba(234,78,164,0) 100%);}
     .ele-right-white-balloon{    width: 17%;top: 30%;margin-right: -60%;} */
    .ele-main-kv-pin{bottom: 320px;right: -10%;width: 320px;left: auto;}
}
@media (max-width: 991.98px){
   

    .cont-r {
        border-radius: 23px;
        padding: 3px;
    }
    .banner-cont .cont-l .banner-t-1 {font-size: 2em;}
    .banner-cont .cont-l .banner-t-2 {font-size: 1.4em;}
    .s-camdetail-gift{margin-top: 2em;}
   
    .s-camdetail-gift.s-camdetail-gift-2 {margin-top: 2em;}
    .s-camdetail-gift .txt-light img{width: 50px;}
    .sec-home-campaign{    padding: 2em 40px 10em;margin-top: 1em;}
    .s-camdetail-gift h5 { font-size: 1em;}
    

    
    .group-bg-foot { background-size:1280px}
    .s-hreward {font-size: 2.8vw;}
    /* .ele-right-white-balloon{display:none;}
    .ele-left-white-balloon{width: 25px;margin-top: -117%;}
    .ele-left-gift{    transform: translateY(-21%) rotate(-45deg);width: 20%;margin-top: -50%;} */
    .s-hreward  .s-hreward-title{width: 70%;}

    /* .videoplay-icon{height: 60px;} */

    /* .ele-main-kv-pin{right: -70px;width: 320px;left: auto;} */
    .ele-main-kv-pin {
    right: -190px;
    width: 450px;left: auto;}
    .sec-home-campaign.bg-gd{background: linear-gradient(360deg, rgb(197 22 140 / 75%) -10.91%, #FBB015 164.05%);}

    .s-hreward-text{font-size:0.9em;}
}
@media (max-width: 767.98px){
    .ele-main-kv-pin{display: none;}
    .sec-home-campaign.bg-gd{background: linear-gradient(360deg, rgb(197 22 140 / 100%) -10.91%, #FBB015 164.05%);}
    img.ele-arrow-title{display: none;}
    .banner-cont .logo-kv {
        height: auto;
        width: 30%;
    }
    .banner-cont{
        flex-wrap: wrap;
    }
    .banner-cont .cont-l{
        width: 100%;margin-bottom:20px;
    }
    .banner-cont .cont-r{
        width: 100%;
    }
    .s-camdetail-gift-1 > img { height: 160px;transform: scale(1.06);}
    .s-camdetail-gift-2 > img { height: 160px;transform: scale(1.06);}
    .s-camdetail-gift h5{font-size: 4vw;}
    .sec-home-main{background: url(../assets/img/home/bg-banner-home-m.jpg)  center top no-repeat;     background-size: contain;}
    .cont-r{border-radius: 10px;padding: 4px;}
    .cont-r .swiper{border-radius:7px;}
    .banner-cont .cont-l{margin-bottom:60px;}
    .cont-r .swiper .swiper-slide .box-connt-baner{    border-radius: 7px;}
    .sec-home-campaign{margin-top:1em;}
    .swiper-pagination-bullet{background-color: rgba(217, 217, 217, 0.4);opacity: 1;}


    .sec-home-top-rank  .scroll-swap{flex-wrap: unset;overflow-x: scroll;scroll-snap-type: x mandatory;padding-bottom: 20px;}
    /* .sec-home-top-rank  .scroll-swap .scroll-swap-item{flex: 0 0 40%;scroll-snap-align: start;} */
    .sec-home-top-rank  .scroll-swap::-webkit-scrollbar {display: none;}
    .sec-home-top-rank  .scroll-swap {-ms-overflow-style: none;  scrollbar-width: none; }
    .sec-home-top-rank  .scroll-swap .scroll-swap-item{flex: 0 0 60%;scroll-snap-align: start;}

    .s-hreward {
        font-size: 6vw;
    }
    .s-hreward-1 {margin-bottom: 40px;}
    .s-camdetail-gift.s-camdetail-gift-2{margin-top:4em;}
    .sec-home-campaign{padding:2em 10px 2em;}
    .ele-main-kv-cloud{display:none;}
}
@media (max-width: 575.98px){
    .sec-home-top-rank  .scroll-swap .scroll-swap-item{flex: 0 0 70%;scroll-snap-align: start;}
    .s-camdetail-gift-2 > img{position: relative;right: 30px;}

    

    .banner-cont .cont-l .banner-t-2{font-size: var(--font-size-h5);}
    .banner-cont .cont-l .banner-t-1 {
    font-size: 1.4em;
}
    .banner-cont .logo-kv{width: 40%;}
    .banner-cont .cont-l{margin-bottom:90px;}

    .ele-left-cloud {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateY(-50%);
    margin-left: -99%;
    width: 99%;
}   
.ele-left-gift {
    transform: translateY(-50%) rotate(-45deg);
    width: 20%;
    margin-top: -100%;
    left: 60%;
}
.ele-left-white-balloon {
    width: 22px;
    margin-top: -128%;
    margin-left: -45%;
}

.ele-right-cloud {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translateY(12%);
    margin-right: -109%;
    width: 100%;
}
.ele-right-gold-balloon {
    position: absolute;
    top: 42%;
    right: 70%;
}

    .ele.ele-right-gift {
    top: 49%;
    right: 60%;
}

.box-footer{flex-wrap: wrap;justify-content: center;}
.box-footer a{order: 0;margin-bottom: 10px;}
.box-footer .logo-footer{order: 1;}

}


</style>
