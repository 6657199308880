<template>  
   
    <div class="popup-login">
        <transition name="fade">
            <div class="login-page">
              
                <div class="wallpaper-login" v-on:click="$emit('close-login'), body.removeAttribute('style')"></div>
                
                <!-- <transition name="fade">
                    <div v-if="registerActive" class="wallpaper-register" v-on:click="$emit('close-login')"></div>
                </transition> -->

                <div class="row position-relative box-pop-cont-fade" style="z-index: 2;">
                    <div class="col-lg-12 col-md-10 col-sm-10 ">
                        <div class="pop-container bg-white rounded-4">
                            <div class="popup-close popout" data-popup="popup-content" v-on:click="$emit('close-login'), body.removeAttribute('style')"><img src="@/assets/img/skin/icon-close.svg" alt=""></div>
                            <template v-if="!registerStatus &&  !loginStatus">
                                <div v-if="registerActive == false" class="card login text-center border-0" >
                                    <h2 ><span class="gd-hl txt-w-xl">เข้าสู่ระบบ</span></h2>
                                    <div class="mb-3 login-sub">
                                        <p>โครงการ Unseen New Chapters ปักหมุดใหม่ <span style="display:inline-block;">เปิดไทยมุมต่าง</span> เป็นส่วนหนึ่งในโครงการตามพันธกิจและบริการของการท่องเที่ยวแห่งประเทศไทย (ททท.) โดยการลงทะเบียนเก็บรวบรวมใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน เป็นไปเพื่อประโยชน์ในการเก็บรวบรวมฐานข้อมูลสถิติผู้สนใจในกิจกรรมและร่วมลุ้นรับของรางวัลจากการจัดกิจกรรม Unseen New Chapters</p>
                                    </div>
                                    <form @submit.prevent="login">
                                        <div class="form-group text-center mb-3">
                                            <label class="h5 gray-darkest"  for=""><b>เบอร์โทรศัพท์</b></label>
                                            <input v-model="phone" type="tel" class="form-control" placeholder="กรุณากรอกเบอร์โทรศัพท์"  maxlength="10"  @keypress="isNumber($event)" >
                                            <p class="txt-w-l danger mt-2" v-if="phoneEmpty != false"><small>{{ phoneVertify }}</small></p>
                                        </div>
                                        <div class="popup-footer fw">
                                            <p class="gray-light mb-3"><small>ระบบจะทำการส่ง OTP <br>ไปยังหมายเลขโทรศัพท์มือถือของท่าน</small></p>
                                            <div class="footer-btn d-block mb-md-4">
                                               <div class="fw mb-md-3">
                                                    <button type="submit" :disabled="disableBtn" class="btn btn-ctr-primary rounded-pill ">ส่ง OTP</button>
                                               </div>
                                                
                                                <div class="fw register-m">
                                                    <a href="#" class="txt-w-l text-decoration-underline" @click="swichAuth('register'),handleClick('Auth','register-popup')">ลงทะเบียน</a>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div v-else  class="card register border-0" >
                                    <div class="pop-cont">
                                        <div class="pop-cont-l">
                                            <h3 class="text-md-start text-center" ><span class="gd-hl txt-w-xl">ลงทะเบียนลุ้นรางวัล</span></h3>
                                            <div class="register-sub mb-3">
                                                <p>
                                                    โครงการ Unseen New Chapters ปักหมุดใหม่ เปิดไทยมุมต่าง และเว็บไซต์นี้ <a class="text-decoration-underline" href="https://uat.unseennewchapters.com/">(https://uat.unseennewchapters.com/)</a> เป็นส่วนหนึ่งในโครงการตามพันธกิจและบริการของการท่องเที่ยวแห่งประเทศไทย (ททท.)
                                                    โดยการลงทะเบียนเก็บรวบรวมใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน เป็นไปเพื่อประโยชน์ในการเก็บรวบรวมฐานข้อมูลสถิติผู้สนใจในกิจกรรมและร่วมลุ้นรับของรางวัลจากการจัดกิจกรรม Unseen New Chapters 
                                                    และเพื่อวัตถุประสงค์อื่นใดที่เกี่ยวเนื่องกันตามที่ปรากฏในนโยบายความเป็นส่วนตัวเท่านั้น ท่านสามารถสามารถยกเลิกความยินยอมสำหรับกิจกรรมข้างต้นเสียเมื่อใดก็ได้ โดยการส่งข้อความไปยัง <span style="color: #ec268f;"><b>การท่องเที่ยวแห่งประเทศไทย</b></span> <a class="text-decoration-underline" href="mailto:unseennewchapters@gmail.com">unseennewchapters@gmail.com</a> 
                                                    ทั้งนี้ การถอนความยินยอมจะส่งผลให้ท่านไม่สามารถเข้าร่วมกิจกรรมและรับของรางวัลได้ แต่จะไม่ส่งผลกระทบต่อการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่ได้ดาเนินการไปแล้วบนฐานความยินยอมนั้น 
                                                    ทั้งนี้ ท่านสามารถศึกษาวิธีการที่ ททท. เก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล และวิธีการใช้สิทธิของเจ้าของข้อมูลส่วนบุคคลได้จาก <a class="text-decoration-underline" href="https://thai.tourismthailand.org/Information/นโยบายความเป็นส่วนตัว" target="_blank">นโยบายส่วนบุคคล</a>
                                                </p>
                                            </div>
                                            <form  @submit.prevent="register">
                                                <div class="form-group mb-4">
                                                    <label class="h5 gray-darkest" for=""><b>ชื่อ - นามสกุล</b></label>
                                                    <input type="text" class="form-control"  @input="validateInput" placeholder="กรุณากรอกชื่อ นามสกุล" v-model="name" >
                                                    <p class="txt-w-l danger mt-2" v-if="nameEmpty != false"><small>{{ nameVertify }}</small></p>
                                                </div>
                                                <div class="form-group mb-4">
                                                    <label class="h5 gray-darkest"  for=""><b>เบอร์โทรศัพท์</b></label>
                                                    <input type="tel" class="form-control phone-number" @keypress="isNumber($event)" placeholder="กรุณากรอกเบอร์โทรศัพท์" maxlength="10" v-model="phone">
                                                    <p class="txt-w-l danger mt-2" v-if="phoneEmpty != false"><small>{{ phoneVertify }}</small></p>
                                                </div>
                                                <div class="form-group check-group ">
                                                    <input id="register_pdpa" type="checkbox" name="register_pdpa" class="check" value="1" v-model="registerPdpa">
                                                    <!-- <label class="form-check-label gray-light" for="register_pdpa">ข้าพเจ้ายอมรับ <a href="https://thai.tourismthailand.org/Information/นโยบายความเป็นส่วนตัว" target="_blank">นโยบายส่วนบุคคล</a> ให้สามารถจัดเก็บข้อมูลเพื่อใช้ในการติดต่อ และให้ข้อมูลอันเป็นประโยชน์ข้าพเจ้า
                                                        <br>
                                                        <p class="txt-w-l danger " v-if="registerPdpaEmpty != false"><small>{{ registerPdpaVertify }}</small></p>
                                                    </label> -->
                                                    <label class="form-check-label gray-light" for="register_pdpa">ข้าพเจ้าได้อ่านและยอมรับนโยบายความเป็นส่วนตัว และยินยอมให้ ททท. เก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าตามวัตถุประสงค์ข้างต้น
                                                        <br>
                                                        <p class="txt-w-l danger " v-if="registerPdpaEmpty != false"><small>{{ registerPdpaVertify }}</small></p>
                                                    </label>
                                                
                                                </div>
                                                <div class="popup-footer fw mt-2 text-center">
                                                    <div class="footer-btn  register d-block">
                                                       <div class="fw mb-md-2">
                                                            <button type="submit" :disabled="disableBtn"  class="btn btn-ctr-primary me-0 rounded-pill">ลงทะเบียน</button>
                                                       </div>
                                                       <div class="fw">
                                                            <a href="#" class="txt-w-l text-decoration-underline"  @click="swichAuth('login'),handleClick('Auth','login-popup')">เข้าสู่ระบบ</a>
                                                       </div>
                                                    </div>
                                                </div>
                                            </form>
                                        
                                        </div>
                                        <div class="pop-cont-r">
                                            <img src="@/assets/img/auth/bg-register.jpg" alt="">
                                            <img class="abs ele-underground" src="@/assets/img/auth/underground-register.jpg" alt="">
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <div v-if="registerStatus ||  loginStatus" class="card login text-center border-0" >
                                <h2 ><span class="gd-hl txt-w-xl">ยืนยันการลงทะเบียน</span></h2>
                                <form @submit.prevent="otpVerify">
                                    <div class="form-group text-center mb-3">
                                        <label class="h5 gray-darkest"  for=""><b>รหัส OTP</b></label>
                                        <input v-model="otp_number" type="text" class="form-control" placeholder="กรุณากรอกรหัส OTP"  maxlength="6"  @keypress="isNumber($event)">
                                        <p class="txt-w-l danger mt-2" v-if="sendOTPEmpty != false"><small>รหัส OTP ไม่ถูกต้อง กรุณากรอกใหม่</small></p>
                                    </div>
                                    <div class="popup-footer fw text-center">
                                        <p class="gray-light mb-3"><small>กรุณาตรวจสอบรหัส OTP ข้อความในมือถือของท่าน</small>
                                            <br>
                                            <small class="w-100 d-flex justify-content-center align-items-center text-center">
                                                <p class="txt-w-l mt-2 out-count gray-light countdown-timer" ><small>ขอ OTP อีกครั้ง ( <span id="countdowntimer">60</span> วินาที</small> )</p>
                                                <a class="resend-otp txt-w-l primary mt-2" style="display: none;" @click="sentOtp(phoneNumber,keepType,2)" href="javascript:void(0)"><small>ส่ง OTP อีกครั้ง</small></a>
                                            </small>
                                        </p>
                                        <div class="footer-btn ">
                                            <button type="submit" class="btn btn-ctr-primary rounded-pill" >ยืนยัน</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
   
   
</template>


<script>
/* eslint-disable */
import { setFunction } from '@/global'
import axios from 'axios';
import Cookies from 'js-cookie';
import { clickTracking } from '@/main';
//  import jwt from 'jsonwebtoken';
export default {
    props: ['dataCheck','registerActiveCheck'],
    data() {
        return {
            registerActive: false,
            registerStatus: false,
            loginStatus: false,
            dataToken: Cookies.get('token'),
            phoneEmpty: false,
            phoneVertify: '',
            nameEmpty: false,
            nameVertify: '',
            registerPdpaEmpty: false,
            registerPdpaVertify: '',
            KeepToken_sms: '',
            keepType: '',
            couttimer: true,
            phoneNumber: '',
            sendOTPEmpty: false,
            checkRound: 1,
            name: '',
            phone: null,
            registerPdpa: 0,
            body:  document.querySelector('body'),
            disableBtn:false,
            // authSatus: sessionStorage.getItem('user_status'),

        }
    },
    mounted(){

        setFunction();
       setTimeout(() => {
            document.querySelector('.box-pop-cont-fade').classList.add('active');
            document.querySelector('.wallpaper-login').classList.add('active');
             
       }, 100);
        if (this.dataCheck != '') {
            this.registerActive = JSON.parse(this.dataCheck);
        }
        this.body.style.overflow = 'hidden';

       
       
       
    },
    methods: {
        async getToken() {
            try {
                    const tokenResponse = await axios.get('https://uat.unseennewchapters.com/nx/API/get_token');
                    this.token = tokenResponse.data.hash;
                    Cookies.set('token', this.token , { expires: 90 })
                    return this.token;
            } catch (error) {
            }
        },
        handleClick(name,value) {
            this.clickTracking(name,value);
            // Additional logic for handling the button click
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        validateInput() {
            this.name = this.name.replace(/[0-9]/g, '');
        },
        swichAuth(status){
            const popup = document.querySelector('.popup-login')
            if (status === 'login' ) {
                popup.classList.remove('popup-register-form');
                popup.classList.add('popup-login-form');
                this.registerActive = false;
                this.phoneEmpty = false;
                this.nameEmpty = false;
                this.registerPdpaEmpty = false;
            }else{
                popup.classList.remove('popup-login-form');
                popup.classList.add('popup-register-form');
                this.registerActive = true;
                this.phoneEmpty = false;
                this.nameEmpty = false;
                this.registerPdpaEmpty = false;
               
            }
        },
        countDownOtp(){
            var timeleft = 60;

            var downloadTimer = setInterval(function(){
            timeleft--;
            document.getElementById("countdowntimer").textContent = timeleft;
            if(timeleft <= 0){
                clearInterval(downloadTimer);
                document.querySelector(".countdown-timer").style.display = "none";
                document.querySelector(".resend-otp").style.display = "block";
            }

            },1000);
        },
        async login() {
            this.disableBtn = true;
            this.dataToken = await this.getToken();
         
            if (this.phone == undefined || this.phone == '') {
                this.disableBtn = false;
                console.log(this.disableBtn);
                if(this.phone.length > 10) {
                    this.phoneEmpty = true;
                    this.phoneVertify = 'กรุณากรอกหมายเลขโทรศัพท์ให้ครบ'
                }else{
                    this.phoneEmpty = true;
                    this.phoneVertify = 'กรุณากรอกหมายเลขโทรศัพท์'
                }
            }else{
                
                this.phoneEmpty = false;
                try {
                    this.$emit('load-api', true);
                    const formData = new FormData();
                    formData.append('phone', this.phone);
                    formData.append('token', this.dataToken );

                    const response = await axios.post('https://uat.unseennewchapters.com/nx/API/login', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${this.dataToken}`
                        }
                    });
                    if (response.data.rs == true) {
                        this.sentOtp(this.phone,'login',1)
                        this.registerStatus = true;
                        this.phoneEmpty = false;
                        setTimeout(() => {
                            this.$emit('load-api', false);
                        }, 500);
                    }else{
                        this.phoneEmpty = true;
                        this.phoneVertify = 'หมายเลขโทรศัพท์นี้ยังไม่ลงทะเบียน กรุณาลงทะเบียนก่อน'
                        setTimeout(() => {
                            this.$emit('load-api', false);
                        }, 500);
                    }
                    
                } catch (error) {
                    setTimeout(() => {
                        this.$emit('load-api', false);
                        this.$emit('login-success', true,'login-api');
                    }, 500);
                    
                }
            }
        },
        
        async register() {
            this.disableBtn = true;
            this.dataToken = await this.getToken();
            
            
            if (this.name == undefined || this.name == '') {
                this.disableBtn = false;
                this.nameEmpty = true;
                this.nameVertify = 'กรุณากรอกชื่อและนามสกุล'
            }else{
                this.nameEmpty = false;
            }

            
            if (this.phone == undefined || this.phone == '') {
                this.disableBtn = false;
                this.phoneEmpty = true;
                this.phoneVertify = 'กรุณากรอกหมายเลขโทรศัพท์'
            }else{
                this.disableBtn = false;
                if (this.phone.length < 10 ) {
                    this.phoneEmpty = true;
                    this.phoneVertify = 'กรุณากรอกหมายเลขโทรศัพท์ให้ครบ'
                }else{
                    this.phoneEmpty = false;
                    this.phoneVertify = ''
                }
            }

          

            if (this.registerPdpa == 0 ) {
                this.disableBtn = false;
                this.registerPdpaEmpty = true;
                this.registerPdpaVertify = 'กรุณากดยอมรับนโยบายส่วนบุคคลให้สามารถจัดเก็บข้อมูล';
            }else{
                this.registerPdpaEmpty = false;
            }

            if(this.name != '' && this.phone != ''&& this.registerPdpa != 0 ){
                try {
                    this.$emit('load-api', true);
                    const formData = new FormData();
                    formData.append('name', this.name);
                    formData.append('phone', this.phone);
                    formData.append('token', this.dataToken );

                    const response = await axios.post('https://uat.unseennewchapters.com/nx/API/register', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${this.dataToken}`
                        }
                    });
                    // this.registerStatus = true;
                    if (response.data.rs == true) {
                        
                        this.sentOtp(this.phone,'register',1)
                        this.registerStatus = true;
                        const popup = document.querySelector('.popup-login')
                        popup.classList.remove('popup-register-form');
                        popup.classList.add('popup-login-form');
                        this.$emit('load-api', false);
                    }else{
                        this.phoneEmpty = true;
                        this.phoneVertify = 'คุณลงทะเบียนเรียบร้อยแล้ว กรุณาเข้าสู่ระบบ'
                        this.$emit('load-api', false);
                    }
                    
                } catch (error) {
                    this.$emit('load-api', false);
                    this.$emit('login-success', true,'register-api');
                    // console.error(error);
                
                }
            }
           
        },
        async sentOtp(phone,type,round){
            try {
                    this.checkRound = round;
                    // // console.log( this.checkRound );
                    this.phoneNumber = phone;
                    this.keepType = type;
                    const keepType = type;
                    const formData = new FormData();
                    formData.append('phone', phone);
                    formData.append('token', this.dataToken);
                    const otpPost = await axios.post('https://uat.unseennewchapters.com/nx/API/send_otp', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${this.dataToken}`
                        }
                    });
                    if (otpPost.data.data.status == 'success') {
                        // this.otpVerify(otpPost.data.data.token, keepType)
                        this.KeepToken_sms = otpPost.data.data.token;
                        this.keepType = keepType;
                        if (this.checkRound == 2) {
                            document.querySelector(".countdown-timer").style.display = "block";
                            document.querySelector(".resend-otp").style.display = "none"; 
                            setTimeout(() => {
                                this.countDownOtp();
                            }, 100);
                        }else{
                            this.countDownOtp();
                        }
                    }else{
                        // // // console.log('sentOtp ไม่ถูกนะ ไม่ผ่านจ้า');
                    }
                
                
            } catch (error) {
                // console.error(error);

                
            }
        },
        async otpVerify(){
            if (this.otp_number == undefined) {
                this.sendOTPEmpty = true;
            }else{
                try {
                    this.$emit('load-api', true);
                    this.sendOTPEmpty = false;
                    const formData = new FormData();
                    formData.append('otp_number', this.otp_number);
                    formData.append('token_sms', this.KeepToken_sms);
                    formData.append('type', this.keepType);
                    formData.append('token', this.dataToken);
                    const otpVerify = await axios.post('https://uat.unseennewchapters.com/nx/API/otp_verify', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${this.dataToken}`
                        }
                    });

                    if (otpVerify.data.rs == true) {

                        // Get current time in Thailand (UTC+7) 
                        // Calculate expiration time (1 day)
                        // let now = new Date();
                        // let time = now.getTime();
                        // time += 7 * 60 * 60 * 1000; // UTC+7 (Bangkok) offset in milliseconds
                        // now.setTime(time);

                       
                        // let expires = new Date(now.getTime() + 24 * 60 * 60 * 1000).toUTCString();

                        // // Set the cookie
                        // document.cookie = 'login_status=1;expires=' + expires + ';path=/';

                        // sessionStorage.setItem('USER_INFO', otpVerify.data.userAssets);



                        // Get current time in Thailand (UTC+7)
                        let now = new Date();
                        let time = now.getTime();
                        time += 7 * 60 * 60 * 1000; // UTC+7 (Bangkok) offset in milliseconds
                        now.setTime(time);

                        // Calculate expiration time (1 month)
                        let expirationDate = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000);
                        let expires = expirationDate.toUTCString();

                        // Set the cookie
                        document.cookie = 'login_status=1;expires=' + expires + ';path=/';

                        sessionStorage.setItem('USER_INFO', otpVerify.data.userAssets);
                        setTimeout(() => {
                            this.$emit('load-api', false);
                            window.location.reload();
                        }, 200);
                    }else{
                        this.$emit('load-api', false);
                        this.sendOTPEmpty = true;
                    }
                
                    
                } catch (error) {
                    setTimeout(() => {
                        this.$emit('vote-success', false);
                        this.$emit('load-api', false); 
                    }, 100);
                    // console.error(error);
                }
            }
            
        }
    }
}
</script>


<style scoped>
.popup-login {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;transition: all ease-in-out .3s;
}

.footer-btn{
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-sub p{
    font-size: .8em;
    line-height: 1.6;
}
.register-sub{
    overflow: hidden;
    max-height: 105px;
    overflow-y: scroll;
    padding-right: 10px;
    width: 100%;
}
.register-sub {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.register-sub::-webkit-scrollbar {
  width: 5px;
  background-color: #f1f1f1;
}

.register-sub::-webkit-scrollbar-thumb {
  background-color: #888;
}

.register-sub::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}



.register-sub a{color: var(--color-primary);}
.register-sub p{
    font-size: .7em;
    line-height: 1.6;
}
.footer-btn button{min-width: 150px;margin-right: 10px;}

.box-pop-cont-fade{opacity: 0;transition: all ease-in-out .5s;}
  .box-pop-cont-fade.active{opacity: 1;}
.login-page {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
}

.login-page .wallpaper-login {
    background: rgba(0, 0, 0, 0.8);
    background-size: cover;
    height: 100%;
    position: absolute;top: 0;
    width: 100%;opacity: 0;transition: all ease-in-out .5s;
}
.login-page .wallpaper-login.active{opacity: 1;}
.popup-login h2{margin-bottom: 10px;}
.pop-container {
    min-width: 500px;padding: 30px 50px;overflow: hidden;max-width: 750px;
    position: relative;
}

.login-page .fade-enter-active,
.login-page .fade-leave-active {
    transition: all ease-in-out .10s;
}

.login-page .fade-enter,
.login-page .fade-leave-to {
    opacity: 0;
}

.login-page .wallpaper-register {
    background: rgba(0, 0, 0, 0.5);
    background-size: cover;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.login-page input{
    color: #000000;
    background: #F5F5F5;
    box-shadow: inset 0px 0px 10px rgba(38, 38, 38, 0.1);
    border-radius: 15px;
    padding: 15px 20px;
    border: 0;
}

.popup-register-form:not(.isOtp) .pop-container{padding: 0 0 0 20px;
    max-width: 750px;
}

.pop-cont {
    display: flex;
    align-items: center;
}
.pop-cont-r {
    width: auto;
    position: relative;padding-left: 20px;
}
.pop-cont-l {width: 100%;
    display: block;}
 .pop-cont-r  img:not(.abs){
    width: 210px;
    display: block;
}
img.abs.ele-underground {
    position: absolute;
    right: 0;
    top: 10%;
}

.login-page input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-gray-light);
  opacity: 1; /* Firefox */
}

.login-page input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--color-gray-light);
}

.login-page input::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--color-gray-light);
}

.popup-login .h5 {
    font-size: 1rem;
}
.check-group label{font-size: 0.8em;}

@media (max-width: 991.98px){
  .popup-register-form .pop-container {width: 90vw;}
  /* .pop-cont-l{max-height: 500px; overflow-y: scroll;} */
}
@media (max-width: 767.98px){
    .popup-register-form:not(.isOtp) .pop-container{padding: 30px 20px;max-width: 750px;}
    .pop-cont-r{display: none; }
   
}
@media (max-width: 575.98px){
    .pop-container {
        min-width: auto;
        width: 90vw;
        padding: 30px 20px;
        overflow: hidden;
        max-width: 750px;
    }
    .popup-register-form:not(.isOtp) .pop-container{padding: 40px 20px 30px;}
    .footer-btn{flex-wrap: wrap;}
    .footer-btn button{margin-bottom: 20px; width: auto;}
    .footer-btn a{width: 100%;text-decoration: underline;}

    .register.footer-btn{flex-wrap: wrap;}
    .register.footer-btn button{margin-bottom: 20px; width: auto;order: 0;width: 100%;}
    .register.footer-btn a{width: 100%;text-decoration: underline;order: 1; text-align: center;}
    .popup-close{width: 30px;height: 30px;top: 10px;right: 20px;}

    .login-page input{
        border-radius: 5px;
        padding: 10px 20px;
        font-size: 14px;
    }
    @media (max-height: 600.98px){
        
    }
/* 
    .pop-cont-l {
            max-height: 410px;
            overflow-y: scroll;
            padding-right: 10px;
            width: calc(100% + 10px);
        }
        .register-sub{overflow: auto;max-height: none;overflow-y: auto;} */
}
</style>

<style>
    .popup-login-form .row{justify-content: center;}

    @media (max-width: 575.98px){
        .popup-register-form    .pop-cont-l {
            max-height: 500px;
            overflow-y: scroll;
            padding-right: 10px;
            width: calc(100% + 10px);
        }
        .register-sub{overflow: auto !important;max-height: none !important;overflow-y: auto !important;}

        @media (max-height: 600.98px){
            .popup-register-form    .pop-cont-l {
            max-height: 400px;
            overflow-y: scroll;
            padding-right: 10px;
            width: calc(100% + 10px);
            }
        }
    }

  

   
</style>